/* iTechNotion Pvt. Ltd */

/*********************************/
/*         INDEX                 */
/*================================
 *     01.  Variables            *
 *     02.  Bootstrap Custom     *
 *     03.  Components           *
 *     04.  General              *
 *     05.  Helper               *
 *     06.  Menu                 *
 *     07.  Home / Landing       *
 *     08.  Features / Services  *
 *     09.  Chat                 *
 *     10.  Testi                *
 *     11.  Blog                 *
 *     12.  Calendar             *
 *     13.  Shop                 *
 *     14.  price                *
 *     15.  User                 *
 *     16.  Simple Bar           *
 *     17.  Works                *
 *     18.  Footer               *
 ================================*/
/*********************************/
/*         Variables             */
/*===============================*/
/* @import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap"); */

/* Axiforma Font */
@font-face {
  font-family: "axiforma";
  src: url("../../../src/assets/fonts/axiforma/Axiforma-Light.eot")
      format("embedded-opentype"),
    url("../../../src/assets/fonts/axiforma/Axiforma-Light.woff") format("woff"),
    url("../../../src/assets/fonts/axiforma/Axiforma-Light.woff2")
      format("woff2"),
    url("../../../src/assets/fonts/axiforma/Axiforma-Light.ttf")
      format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "axiforma";
  src: url("../../../src/assets/fonts/axiforma/Axiforma-Regular.eot")
      format("embedded-opentype"),
    url("../../../src/assets/fonts/axiforma/Axiforma-Regular.woff")
      format("woff"),
    url("../../../src/assets/fonts/axiforma/Axiforma-Regular.woff2")
      format("woff2"),
    url("../../../src/assets/fonts/axiforma/Axiforma-Regular.ttf")
      format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "axiforma";
  src: url("../../../src/assets/fonts/axiforma/Axiforma-Medium.eot")
      format("embedded-opentype"),
    url("../../../src/assets/fonts/axiforma/Axiforma-Medium.woff")
      format("woff"),
    url("../../../src/assets/fonts/axiforma/Axiforma-Medium.woff2")
      format("woff2"),
    url("../../../src/assets/fonts/axiforma/Axiforma-Medium.ttf")
      format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "axiforma";
  src: url("../../../src/assets/fonts/axiforma/Axiforma-SemiBold.eot")
      format("embedded-opentype"),
    url("../../../src/assets/fonts/axiforma/Axiforma-SemiBold.woff")
      format("woff"),
    url("../../../src/assets/fonts/axiforma/Axiforma-SemiBold.woff2")
      format("woff2"),
    url("../../../src/assets/fonts/axiforma/Axiforma-SemiBold.ttf")
      format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "axiforma";
  src: url("../../../src/assets/fonts/axiforma/Axiforma-Bold.eot")
      format("embedded-opentype"),
    url("../../../src/assets/fonts/axiforma/Axiforma-Bold.woff") format("woff"),
    url("../../../src/assets/fonts/axiforma/Axiforma-Bold.woff2")
      format("woff2"),
    url("../../../src/assets/fonts/axiforma/Axiforma-Bold.ttf")
      format("truetype");
  font-weight: 700;
}

h1,
.h1 {
  font-size: 42px;
  line-height: 1.5;
  font-weight: 500;
}

h2,
.h2 {
  font-size: 36px;
  line-height: 1.5;
  font-weight: 500;
}

h3,
.h3 {
  font-size: 30px;
  line-height: 1.5;
  font-weight: 500;
}

h4,
.h4 {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 500;
}

h5,
.h5 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
}

h6,
.h6 {
  font-size: 15px;
  line-height: 1.5;
  font-weight: 500;
}

.display-1 {
  font-size: 80px;
}

.display-2 {
  font-size: 72px;
}

.display-3 {
  font-size: 64px;
}

.display-4 {
  font-size: 56px;
}

.display-5 {
  font-size: 48px;
}

.display-6 {
  font-size: 40px;
}

.bg-primary {
  background-color: #e05735 !important; /* #2f55d4 */
}

.bg-soft-primary {
  background-color: rgba(224, 87, 53, 0.1) !important;
  border: 1px solid rgba(224, 87, 53, 0.1) !important;
  color: #e05735 !important;
}

.text-primary {
  color: #e05735 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #cf5132 !important;
}

.bg-secondary {
  background-color: #5a6d90 !important;
}

.bg-soft-secondary {
  background-color: rgba(90, 109, 144, 0.1) !important;
  border: 1px solid rgba(90, 109, 144, 0.1) !important;
  color: #5a6d90 !important;
}

.text-secondary {
  color: #5a6d90 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #506180 !important;
}

.bg-success {
  background-color: #2eca8b !important;
}

.bg-soft-success {
  background-color: rgba(46, 202, 139, 0.1) !important;
  border: 1px solid rgba(46, 202, 139, 0.1) !important;
  color: #2eca8b !important;
}

.text-success {
  color: #2eca8b !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #29b57d !important;
}

.bg-warning {
  background-color: #f17425 !important;
}

.bg-soft-warning {
  background-color: rgba(241, 116, 37, 0.1) !important;
  border: 1px solid rgba(241, 116, 37, 0.1) !important;
  color: #f17425 !important;
}

.text-warning {
  color: #f17425 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ed650f !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-soft-info {
  background-color: rgba(23, 162, 184, 0.1) !important;
  border: 1px solid rgba(23, 162, 184, 0.1) !important;
  color: #17a2b8 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #148ea1 !important;
}

.bg-danger {
  background-color: #e43f52 !important;
}

.bg-soft-danger {
  background-color: rgba(228, 63, 82, 0.1) !important;
  border: 1px solid rgba(228, 63, 82, 0.1) !important;
  color: #e43f52 !important;
}

.text-danger {
  color: #e43f52 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #e1293e !important;
}

.bg-dark {
  background-color: #3c4858 !important;
}

.bg-soft-dark {
  background-color: rgba(60, 72, 88, 0.1) !important;
  border: 1px solid rgba(60, 72, 88, 0.1) !important;
  color: #3c4858 !important;
}

.text-dark {
  color: #3c4858 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #323c49 !important;
}

.bg-muted {
  background-color: #8492a6 !important;
}

.bg-soft-muted {
  background-color: rgba(132, 146, 166, 0.1) !important;
  border: 1px solid rgba(132, 146, 166, 0.1) !important;
  color: #8492a6 !important;
}

.text-muted {
  color: #8492a6 !important;
}

a.text-muted:hover,
a.text-muted:focus {
  color: #75859b !important;
}

.bg-light {
  background-color: #f5f5f7 !important;
}

.bg-soft-light {
  background-color: rgba(248, 249, 252, 0.1) !important;
  border: 1px solid rgba(248, 249, 252, 0.1) !important;
  color: #f8f9fc !important;
}

.text-light {
  color: #f8f9fc !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #e6eaf4 !important;
}

.bg-blue {
  background-color: #e05735 !important;
}

.bg-soft-blue {
  background-color: rgba(224, 87, 53, 0.1) !important;
  border: 1px solid rgba(224, 87, 53, 0.1) !important;
  color: #e05735 !important;
}

.text-blue {
  color: #e05735 !important;
}

a.text-blue:hover,
a.text-blue:focus {
  color: #cf5132 !important;
}

.bg-footer {
  background-color: #202942 !important;
}

.bg-soft-footer {
  background-color: rgba(32, 41, 66, 0.1) !important;
  border: 1px solid rgba(32, 41, 66, 0.1) !important;
  color: #202942 !important;
}

.text-footer {
  color: #202942 !important;
}

a.text-footer:hover,
a.text-footer:focus {
  color: #181e30 !important;
}

a {
  transition: all 0.5s ease;
}

.bg-white-50 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1140px !important;
  }
}

.row > * {
  position: relative;
}

.list-inline-item:not(:last-child) {
  margin-right: 0px;
  margin-bottom: 5px;
}

.margin-nagative.mt-n5 {
  margin-top: -48px;
}

.d-flex .flex-1 {
  flex: 1;
}

.rounded {
  border-radius: 5px !important;
}

.rounded-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.rounded-left {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-bottom {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-right {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-md {
  border-radius: 10px !important;
}

.rounded-lg {
  border-radius: 30px !important;
}

.border {
  border: 1px solid #e9ecef !important;
}

.border-top {
  border-top: 1px solid #e9ecef !important;
}

.border-bottom {
  border-bottom: 1px solid #e9ecef !important;
}

.border-left {
  border-left: 1px solid #e9ecef !important;
}

.border-right {
  border-right: 1px solid #e9ecef !important;
}

.small,
small {
  font-size: 0.9em;
}

.card .card-body {
  padding: 1.5rem;
}

.offcanvas .offcanvas-header {
  height: 71px;
}

.offcanvas .offcanvas-header .dark-version {
  display: none;
}

.offcanvas .offcanvas-header .light-version {
  display: inline-block;
}

.offcanvas .offcanvas-header .btn-close {
  color: #3c4858;
  background: none;
}

.offcanvas .offcanvas-footer {
  height: 60px;
}

.offcanvas.offcanvas-end {
  width: 340px;
}

/*********************************/
/*         Components            */
/*===============================*/
.component-wrapper .title {
  font-size: 20px;
}

.component-wrapper.sticky-bar li {
  margin-bottom: 6px;
}

.component-wrapper.sticky-bar li:last-child {
  margin-bottom: 0px !important;
}

.component-wrapper.sticky-bar li a {
  transition: all 0.5s ease;
}

.component-wrapper.sticky-bar li a:hover,
.component-wrapper.sticky-bar li a:focus {
  color: #e05735 !important;
}

.btn-primary {
  background-color: #e05735 !important;
  border: 1px solid #e05735 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(224, 87, 53, 0.1);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus {
  background-color: #cf5132 !important;
  border-color: #cf5132 !important;
  color: #ffffff !important;
}

.btn-soft-primary {
  background-color: rgba(224, 87, 53, 0.05) !important; /* 224, 87, 53, 0.05 */
  border: 1px solid rgba(224, 87, 53, 0.05) !important;
  color: #e05735 !important;
  box-shadow: 0 3px 5px 0 rgba(224, 87, 53, 0.1);
}

.btn-soft-primary:hover,
.btn-soft-primary:focus,
.btn-soft-primary:active,
.btn-soft-primary.active,
.btn-soft-primary.focus {
  background-color: #e05735 !important;
  border-color: #e05735 !important;
  color: #ffffff !important;
}

.btn-outline-primary {
  border: 1px solid #e05735;
  color: #e05735;
  background-color: transparent;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #e05735;
  border-color: #e05735;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(224, 87, 53, 0.1);
}

.btn-secondary {
  background-color: #5a6d90 !important;
  border: 1px solid #5a6d90 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.1);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.focus {
  background-color: #465571 !important;
  border-color: #465571 !important;
  color: #ffffff !important;
}

.btn-soft-secondary {
  background-color: rgba(90, 109, 144, 0.05) !important;
  border: 1px solid rgba(90, 109, 144, 0.05) !important;
  color: #5a6d90 !important;
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.1);
}

.btn-soft-secondary:hover,
.btn-soft-secondary:focus,
.btn-soft-secondary:active,
.btn-soft-secondary.active,
.btn-soft-secondary.focus {
  background-color: #5a6d90 !important;
  border-color: #5a6d90 !important;
  color: #ffffff !important;
}

.btn-outline-secondary {
  border: 1px solid #5a6d90;
  color: #5a6d90;
  background-color: transparent;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: #5a6d90;
  border-color: #5a6d90;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.1);
}

.btn-success {
  background-color: #2eca8b !important;
  border: 1px solid #2eca8b !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.1);
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus {
  background-color: #25a06e !important;
  border-color: #25a06e !important;
  color: #ffffff !important;
}

.btn-soft-success {
  background-color: rgba(46, 202, 139, 0.05) !important;
  border: 1px solid rgba(46, 202, 139, 0.05) !important;
  color: #2eca8b !important;
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.1);
}

.btn-soft-success:hover,
.btn-soft-success:focus,
.btn-soft-success:active,
.btn-soft-success.active,
.btn-soft-success.focus {
  background-color: #2eca8b !important;
  border-color: #2eca8b !important;
  color: #ffffff !important;
}

.btn-outline-success {
  border: 1px solid #2eca8b;
  color: #2eca8b;
  background-color: transparent;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.focus,
.btn-outline-success:not(:disabled):not(.disabled):active {
  background-color: #2eca8b;
  border-color: #2eca8b;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.1);
}

.btn-warning {
  background-color: #f17425 !important;
  border: 1px solid #f17425 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.1);
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus {
  background-color: #d55b0e !important;
  border-color: #d55b0e !important;
  color: #ffffff !important;
}

.btn-soft-warning {
  background-color: rgba(241, 116, 37, 0.05) !important;
  border: 1px solid rgba(241, 116, 37, 0.05) !important;
  color: #f17425 !important;
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.1);
}

.btn-soft-warning:hover,
.btn-soft-warning:focus,
.btn-soft-warning:active,
.btn-soft-warning.active,
.btn-soft-warning.focus {
  background-color: #f17425 !important;
  border-color: #f17425 !important;
  color: #ffffff !important;
}

.btn-outline-warning {
  border: 1px solid #f17425;
  color: #f17425;
  background-color: transparent;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.focus,
.btn-outline-warning:not(:disabled):not(.disabled):active {
  background-color: #f17425;
  border-color: #f17425;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.1);
}

.btn-info {
  background-color: #17a2b8 !important;
  border: 1px solid #17a2b8 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.1);
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus {
  background-color: #117a8b !important;
  border-color: #117a8b !important;
  color: #ffffff !important;
}

.btn-soft-info {
  background-color: rgba(23, 162, 184, 0.05) !important;
  border: 1px solid rgba(23, 162, 184, 0.05) !important;
  color: #17a2b8 !important;
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.1);
}

.btn-soft-info:hover,
.btn-soft-info:focus,
.btn-soft-info:active,
.btn-soft-info.active,
.btn-soft-info.focus {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  color: #ffffff !important;
}

.btn-outline-info {
  border: 1px solid #17a2b8;
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.focus,
.btn-outline-info:not(:disabled):not(.disabled):active {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.1);
}

.btn-danger {
  background-color: #e43f52 !important;
  border: 1px solid #e43f52 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.1);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.focus {
  background-color: #d21e32 !important;
  border-color: #d21e32 !important;
  color: #ffffff !important;
}

.btn-soft-danger {
  background-color: rgba(228, 63, 82, 0.05) !important;
  border: 1px solid rgba(228, 63, 82, 0.05) !important;
  color: #e43f52 !important;
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.1);
}

.btn-soft-danger:hover,
.btn-soft-danger:focus,
.btn-soft-danger:active,
.btn-soft-danger.active,
.btn-soft-danger.focus {
  background-color: #e43f52 !important;
  border-color: #e43f52 !important;
  color: #ffffff !important;
}

.btn-outline-danger {
  border: 1px solid #e43f52;
  color: #e43f52;
  background-color: transparent;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.focus,
.btn-outline-danger:not(:disabled):not(.disabled):active {
  background-color: #e43f52;
  border-color: #e43f52;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.1);
}

.btn-dark {
  background-color: #3c4858 !important;
  border: 1px solid #3c4858 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.1);
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.btn-dark.focus {
  background-color: #272f3a !important;
  border-color: #272f3a !important;
  color: #ffffff !important;
}

.btn-soft-dark {
  background-color: rgba(60, 72, 88, 0.05) !important;
  border: 1px solid rgba(60, 72, 88, 0.05) !important;
  color: #3c4858 !important;
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.1);
}

.btn-soft-dark:hover,
.btn-soft-dark:focus,
.btn-soft-dark:active,
.btn-soft-dark.active,
.btn-soft-dark.focus {
  background-color: #3c4858 !important;
  border-color: #3c4858 !important;
  color: #ffffff !important;
}

.btn-outline-dark {
  border: 1px solid #3c4858;
  color: #3c4858;
  background-color: transparent;
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.focus,
.btn-outline-dark:not(:disabled):not(.disabled):active {
  background-color: #3c4858;
  border-color: #3c4858;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.1);
}

.btn-muted {
  background-color: #8492a6 !important;
  border: 1px solid #8492a6 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.1);
}

.btn-muted:hover,
.btn-muted:focus,
.btn-muted:active,
.btn-muted.active,
.btn-muted.focus {
  background-color: #68788f !important;
  border-color: #68788f !important;
  color: #ffffff !important;
}

.btn-soft-muted {
  background-color: rgba(132, 146, 166, 0.05) !important;
  border: 1px solid rgba(132, 146, 166, 0.05) !important;
  color: #8492a6 !important;
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.1);
}

.btn-soft-muted:hover,
.btn-soft-muted:focus,
.btn-soft-muted:active,
.btn-soft-muted.active,
.btn-soft-muted.focus {
  background-color: #8492a6 !important;
  border-color: #8492a6 !important;
  color: #ffffff !important;
}

.btn-outline-muted {
  border: 1px solid #8492a6;
  color: #8492a6;
  background-color: transparent;
}

.btn-outline-muted:hover,
.btn-outline-muted:focus,
.btn-outline-muted:active,
.btn-outline-muted.active,
.btn-outline-muted.focus,
.btn-outline-muted:not(:disabled):not(.disabled):active {
  background-color: #8492a6;
  border-color: #8492a6;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.1);
}

.btn-light {
  background-color: #f5f5f7 !important;
  border: 1px solid #f5f5f7 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.1);
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.active,
.btn-light.focus {
  background-color: #d4daed !important;
  border-color: #d4daed !important;
  color: #ffffff !important;
}

.btn-soft-light {
  background-color: rgba(248, 249, 252, 0.05) !important;
  border: 1px solid rgba(248, 249, 252, 0.05) !important;
  color: #f8f9fc !important;
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.1);
}

.btn-soft-light:hover,
.btn-soft-light:focus,
.btn-soft-light:active,
.btn-soft-light.active,
.btn-soft-light.focus {
  background-color: #f5f5f7 !important;
  border-color: #f8f9fc !important;
  color: #ffffff !important;
}

.btn-outline-light {
  border: 1px solid #f8f9fc;
  color: #f8f9fc;
  background-color: transparent;
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.focus,
.btn-outline-light:not(:disabled):not(.disabled):active {
  background-color: #f5f5f7;
  border-color: #f8f9fc;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.1);
}

.btn-blue {
  background-color: #e05735 !important;
  border: 1px solid #e05735 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(224, 87, 53, 0.1);
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active,
.btn-blue.focus {
  background-color: #2443ac !important;
  border-color: #2443ac !important;
  color: #ffffff !important;
}

.btn-soft-blue {
  background-color: rgba(224, 87, 53, 0.05) !important;
  border: 1px solid rgba(224, 87, 53, 0.05) !important;
  color: #e05735 !important;
  box-shadow: 0 3px 5px 0 rgba(224, 87, 53, 0.1);
}

.btn-soft-blue:hover,
.btn-soft-blue:focus,
.btn-soft-blue:active,
.btn-soft-blue.active,
.btn-soft-blue.focus {
  background-color: #e05735 !important;
  border-color: #e05735 !important;
  color: #ffffff !important;
}

.btn-outline-blue {
  border: 1px solid #e05735;
  color: #e05735;
  background-color: transparent;
}

.btn-outline-blue:hover,
.btn-outline-blue:focus,
.btn-outline-blue:active,
.btn-outline-blue.active,
.btn-outline-blue.focus,
.btn-outline-blue:not(:disabled):not(.disabled):active {
  background-color: #e05735;
  border-color: #e05735;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(224, 87, 53, 0.1);
}

.btn-footer {
  background-color: #202942 !important;
  border: 1px solid #202942 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.1);
}

.btn-footer:hover,
.btn-footer:focus,
.btn-footer:active,
.btn-footer.active,
.btn-footer.focus {
  background-color: #0f131f !important;
  border-color: #0f131f !important;
  color: #ffffff !important;
}

.btn-soft-footer {
  background-color: rgba(32, 41, 66, 0.05) !important;
  border: 1px solid rgba(32, 41, 66, 0.05) !important;
  color: #202942 !important;
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.1);
}

.btn-soft-footer:hover,
.btn-soft-footer:focus,
.btn-soft-footer:active,
.btn-soft-footer.active,
.btn-soft-footer.focus {
  background-color: #202942 !important;
  border-color: #202942 !important;
  color: #ffffff !important;
}

.btn-outline-footer {
  border: 1px solid #202942;
  color: #202942;
  background-color: transparent;
}

.btn-outline-footer:hover,
.btn-outline-footer:focus,
.btn-outline-footer:active,
.btn-outline-footer.active,
.btn-outline-footer.focus,
.btn-outline-footer:not(:disabled):not(.disabled):active {
  background-color: #202942;
  border-color: #202942;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.1);
}

.btn {
  padding: 8px 20px;
  outline: none;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  font-weight: 400;
  border-radius: 6px;
}

.btn:focus {
  box-shadow: none !important;
}

.btn.btn-sm {
  padding: 7px 16px;
  font-size: 10px;
}

.btn.btn-lg {
  padding: 14px 30px;
  font-size: 16px;
}

.btn.searchbtn {
  padding: 6px 20px;
}

.btn.btn-pills {
  border-radius: 30px;
}

.btn.btn-light {
  color: #3c4858 !important;
  border: 1px solid #dee2e6 !important;
}

.btn.btn-light:hover,
.btn.btn-light:focus,
.btn.btn-light:active,
.btn.btn-light.active,
.btn.btn-light.focus {
  background-color: #fffcf8 !important; /* #d4daed */
  color: #3c4858 !important;
}

.btn.btn-soft-light {
  color: rgba(60, 72, 88, 0.5) !important;
  border: 1px solid #dee2e6 !important;
}

.btn.btn-soft-light:hover,
.btn.btn-soft-light:focus,
.btn.btn-soft-light:active,
.btn.btn-soft-light.active,
.btn.btn-soft-light.focus {
  color: #3c4858 !important;
}

.btn.btn-outline-light {
  border: 1px solid #dee2e6 !important;
  color: #3c4858 !important;
  background-color: transparent;
}

.btn.btn-outline-light:hover,
.btn.btn-outline-light:focus,
.btn.btn-outline-light:active,
.btn.btn-outline-light.active,
.btn.btn-outline-light.focus {
  background-color: #f5f5f7 !important;
}

.btn.btn-icon {
  height: 36px;
  width: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.btn.btn-icon .icons {
  height: 16px;
  width: 16px;
  font-size: 16px;
}

.btn.btn-icon.btn-lg {
  height: 48px;
  width: 48px;
  line-height: 46px;
}

.btn.btn-icon.btn-lg .icons {
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.btn.btn-icon.btn-sm {
  height: 30px;
  width: 30px;
  line-height: 28px;
}

button:not(:disabled) {
  outline: none;
}

.shadow {
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
}

.shadow-md {
  box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
}

.badge {
  letter-spacing: 0.5px;
  padding: 4px 8px;
  font-weight: 600;
  line-height: 11px;
}

.badge.badge-link:hover {
  color: #ffffff !important;
}

.dropdown-primary .dropdown-menu .dropdown-item:hover,
.dropdown-primary .dropdown-menu .dropdown-item.active,
.dropdown-primary .dropdown-menu .dropdown-item:active,
.dropdown-primary .dropdown-menu .dropdown-item.focus,
.dropdown-primary .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #e05735 !important;
}

.dropdown-secondary .dropdown-menu .dropdown-item:hover,
.dropdown-secondary .dropdown-menu .dropdown-item.active,
.dropdown-secondary .dropdown-menu .dropdown-item:active,
.dropdown-secondary .dropdown-menu .dropdown-item.focus,
.dropdown-secondary .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #5a6d90 !important;
}

.dropdown-success .dropdown-menu .dropdown-item:hover,
.dropdown-success .dropdown-menu .dropdown-item.active,
.dropdown-success .dropdown-menu .dropdown-item:active,
.dropdown-success .dropdown-menu .dropdown-item.focus,
.dropdown-success .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #2eca8b !important;
}

.dropdown-warning .dropdown-menu .dropdown-item:hover,
.dropdown-warning .dropdown-menu .dropdown-item.active,
.dropdown-warning .dropdown-menu .dropdown-item:active,
.dropdown-warning .dropdown-menu .dropdown-item.focus,
.dropdown-warning .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #f17425 !important;
}

.dropdown-info .dropdown-menu .dropdown-item:hover,
.dropdown-info .dropdown-menu .dropdown-item.active,
.dropdown-info .dropdown-menu .dropdown-item:active,
.dropdown-info .dropdown-menu .dropdown-item.focus,
.dropdown-info .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #17a2b8 !important;
}

.dropdown-danger .dropdown-menu .dropdown-item:hover,
.dropdown-danger .dropdown-menu .dropdown-item.active,
.dropdown-danger .dropdown-menu .dropdown-item:active,
.dropdown-danger .dropdown-menu .dropdown-item.focus,
.dropdown-danger .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #e43f52 !important;
}

.dropdown-dark .dropdown-menu .dropdown-item:hover,
.dropdown-dark .dropdown-menu .dropdown-item.active,
.dropdown-dark .dropdown-menu .dropdown-item:active,
.dropdown-dark .dropdown-menu .dropdown-item.focus,
.dropdown-dark .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #3c4858 !important;
}

.dropdown-muted .dropdown-menu .dropdown-item:hover,
.dropdown-muted .dropdown-menu .dropdown-item.active,
.dropdown-muted .dropdown-menu .dropdown-item:active,
.dropdown-muted .dropdown-menu .dropdown-item.focus,
.dropdown-muted .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #8492a6 !important;
}

.dropdown-light .dropdown-menu .dropdown-item:hover,
.dropdown-light .dropdown-menu .dropdown-item.active,
.dropdown-light .dropdown-menu .dropdown-item:active,
.dropdown-light .dropdown-menu .dropdown-item.focus,
.dropdown-light .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #f8f9fc !important;
}

.dropdown-blue .dropdown-menu .dropdown-item:hover,
.dropdown-blue .dropdown-menu .dropdown-item.active,
.dropdown-blue .dropdown-menu .dropdown-item:active,
.dropdown-blue .dropdown-menu .dropdown-item.focus,
.dropdown-blue .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #e05735 !important;
}

.dropdown-footer .dropdown-menu .dropdown-item:hover,
.dropdown-footer .dropdown-menu .dropdown-item.active,
.dropdown-footer .dropdown-menu .dropdown-item:active,
.dropdown-footer .dropdown-menu .dropdown-item.focus,
.dropdown-footer .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #202942 !important;
}

.btn-group .dropdown-toggle:after {
  content: "";
  position: relative;
  right: -4px;
  top: -2px;
  border: solid #ffffff;
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: rotate(45deg);
  margin-left: 0;
  vertical-align: 0;
}

.btn-group .dropdown-menu {
  margin-top: 10px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.btn-group .dropdown-menu:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 45px;
  box-sizing: border-box;
  border: 7px solid #495057;
  border-radius: 0.5px;
  border-color: transparent transparent #ffffff #ffffff;
  transform-origin: 0 0;
  transform: rotate(135deg);
  box-shadow: -2px 2px 2px -1px rgba(22, 28, 45, 0.15);
}

.dropdown .dropdown-toggle:after {
  display: none;
}

.alert-primary {
  background-color: rgba(224, 87, 53, 0.9);
  color: #ffffff;
  border-color: #e05735;
}

.alert-primary .alert-link {
  color: #122258;
}

.alert-outline-primary {
  background-color: #ffffff;
  color: #e05735;
  border-color: #e05735;
}

.alert-secondary {
  background-color: rgba(90, 109, 144, 0.9);
  color: #ffffff;
  border-color: #5a6d90;
}

.alert-secondary .alert-link {
  color: #1f2632;
}

.alert-outline-secondary {
  background-color: #ffffff;
  color: #5a6d90;
  border-color: #5a6d90;
}

.alert-success {
  background-color: rgba(46, 202, 139, 0.9);
  color: #ffffff;
  border-color: #2eca8b;
}

.alert-success .alert-link {
  color: #124d35;
}

.alert-outline-success {
  background-color: #ffffff;
  color: #2eca8b;
  border-color: #2eca8b;
}

.alert-warning {
  background-color: rgba(241, 116, 37, 0.9);
  color: #ffffff;
  border-color: #f17425;
}

.alert-warning .alert-link {
  color: #753208;
}

.alert-outline-warning {
  background-color: #ffffff;
  color: #f17425;
  border-color: #f17425;
}

.alert-info {
  background-color: rgba(23, 162, 184, 0.9);
  color: #ffffff;
  border-color: #17a2b8;
}

.alert-info .alert-link {
  color: #062a30;
}

.alert-outline-info {
  background-color: #ffffff;
  color: #17a2b8;
  border-color: #17a2b8;
}

.alert-danger {
  background-color: rgba(228, 63, 82, 0.9);
  color: #ffffff;
  border-color: #e43f52;
}

.alert-danger .alert-link {
  color: #79111d;
}

.alert-outline-danger {
  background-color: #ffffff;
  color: #e43f52;
  border-color: #e43f52;
}

.alert-dark {
  background-color: rgba(60, 72, 88, 0.9);
  color: #ffffff;
  border-color: #3c4858;
}

.alert-dark .alert-link {
  color: black;
}

.alert-outline-dark {
  background-color: #ffffff;
  color: #3c4858;
  border-color: #3c4858;
}

.alert-muted {
  background-color: rgba(132, 146, 166, 0.9);
  color: #ffffff;
  border-color: #8492a6;
}

.alert-muted .alert-link {
  color: #3d4654;
}

.alert-outline-muted {
  background-color: #ffffff;
  color: #8492a6;
  border-color: #8492a6;
}

.alert-light {
  background-color: rgba(248, 249, 252, 0.9);
  color: #ffffff;
  border-color: #f8f9fc;
}

.alert-light .alert-link {
  color: #8d9dce;
}

.alert-outline-light {
  background-color: #ffffff;
  color: #f8f9fc;
  border-color: #f8f9fc;
}

.alert-blue {
  background-color: rgba(224, 87, 53, 0.9);
  color: #ffffff;
  border-color: #e05735;
}

.alert-blue .alert-link {
  color: #122258;
}

.alert-outline-blue {
  background-color: #ffffff;
  color: #e05735;
  border-color: #e05735;
}

.alert-footer {
  background-color: rgba(32, 41, 66, 0.9);
  color: #ffffff;
  border-color: #202942;
}

.alert-footer .alert-link {
  color: black;
}

.alert-outline-footer {
  background-color: #ffffff;
  color: #202942;
  border-color: #202942;
}

.alert {
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
}

.alert.alert-light {
  background-color: white;
  color: #3c4858;
  border-color: #e9ecef;
}

.alert.alert-dismissible {
  padding-right: 30px;
}

.alert.alert-dismissible .btn-close {
  top: 10px;
  right: 10px;
  padding: 0px;
}

.alert.alert-pills {
  border-radius: 30px;
  display: inline-block;
}

.alert.alert-pills .content {
  font-weight: 600;
}

.pages-heading .title {
  font-size: 32px !important;
  letter-spacing: 1px;
}

.position-breadcrumb {
  position: absolute;
  top: 100%;
  right: 12px;
  left: 12px;
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;
}

.breadcrumb {
  letter-spacing: 0.5px;
  padding: 8px 24px;
  display: block;
}

.breadcrumb .breadcrumb-item {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  padding-left: 0;
  display: inline-block;
}

.breadcrumb .breadcrumb-item a {
  color: #3c4858;
}

.breadcrumb .breadcrumb-item a:hover {
  color: #e05735;
}

.breadcrumb .breadcrumb-item.active {
  color: #e05735;
}

.breadcrumb .breadcrumb-item:before {
  content: "";
}

.breadcrumb .breadcrumb-item:after {
  content: "\F0142" !important;
  font-size: 14px;
  color: #3c4858;
  font-family: "Material Design Icons";
  padding-left: 5px;
}

.breadcrumb .breadcrumb-item:last-child:after {
  display: none;
}

.pagination .page-item:first-child .page-link {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.pagination .page-item:last-child .page-link {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.pagination .page-item .page-link {
  color: #3c4858;
  border: 1px solid #dee2e6;
  padding: 8px 16px;
  font-size: 14px;
}

.pagination .page-item .page-link:focus {
  box-shadow: none;
}

.pagination .page-item .page-link:hover {
  color: #ffffff;
  background: rgba(224, 87, 53, 0.9);
  border-color: rgba(224, 87, 53, 0.9);
}

.pagination .page-item.active .page-link {
  color: #ffffff;
  background: #e05735 !important;
  border-color: #e05735;
  cursor: not-allowed;
}

.avatar.avatar-coin {
  height: 36px;
  width: 36px;
}

.avatar.avatar-ex-sm {
  max-height: 25px;
}

.avatar.avatar-ex-small {
  height: 36px;
  width: 36px;
}

.avatar.avatar-md-sm {
  height: 45px;
  width: 45px;
}

.avatar.avatar-small {
  height: 65px;
  width: 65px;
}

.avatar.avatar-md-md {
  height: 80px;
  width: 80px;
}

.avatar.avatar-medium {
  height: 110px;
  width: 110px;
}

.avatar.avatar-large {
  height: 140px;
  width: 140px;
}

.avatar.avatar-ex-large {
  height: 180px;
  width: 180px;
}

.accordion .accordion-item {
  overflow: hidden;
  border: 0;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  background: transparent;
}

.accordion .accordion-item .accordion-button {
  font-weight: 600;
  text-align: left;
  box-shadow: none;
  padding: 1rem 2.5rem 1rem 1.25rem;
}

.accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
}

.accordion .accordion-item .accordion-button:before {
  content: "\F0143";
  display: block;
  font-family: "Material Design Icons";
  font-size: 16px;
  color: #e05735;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s ease;
}

.accordion .accordion-item .accordion-button.collapsed {
  background-color: #ffffff;
  color: #3c4858 !important;
  transition: all 0.5s;
}

.accordion .accordion-item .accordion-button.collapsed:before {
  top: 15px;
  transform: rotate(180deg);
  color: #3c4858 !important;
}

.accordion .accordion-item .accordion-button:after {
  display: none;
}

.nav-pills {
  margin-bottom: 15px;
  background: #f4f6fa;
}

.nav-pills .nav-link {
  color: #495057 !important;
  padding: 5px;
  transition: all 0.5s ease;
}

.nav-pills .nav-link.nav-link-alt {
  color: #ffffff !important;
}

.nav-pills .nav-link.nav-link-alt.active {
  background: #3c4858 !important;
}

.nav-pills .nav-link.active {
  background: #ffffff;
  color: #e05735 !important;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.nav-pills .nav-link.active .tab-para {
  color: rgba(255, 255, 255, 0.65) !important;
}

.progress-box .title {
  font-size: 15px;
}

.progress-box .progress {
  height: 8px;
  overflow: visible;
  background: #e9ecef;
}

.progress-box .progress .progress-bar {
  border-radius: 6px;
  animation: animate-positive 3s;
  overflow: visible !important;
}

.progress-box .progress .progress-value {
  position: absolute;
  top: -32px;
  right: -15px;
  font-size: 13px;
}

@keyframes animate-positive {
  0% {
    width: 0;
  }
}

.blockquote {
  border-left: 3px solid #dee2e6;
  border-radius: 6px;
  font-size: 16px;
}

.blockquote-wrapper .blockquote {
  position: relative;
  align-self: center;
  border: 2px solid #e05735;
}

.blockquote-wrapper .blockquote:after {
  content: "";
  position: absolute;
  border: 2px solid #e05735;
  border-radius: 0 50px 0 0;
  width: 60px;
  height: 60px;
  bottom: -60px;
  left: 50px;
  border-bottom: none;
  border-left: none;
  z-index: 3;
}

.blockquote-wrapper .blockquote:before {
  content: "";
  position: absolute;
  width: 80px;
  border: 6px solid #ffffff;
  bottom: -3px;
  left: 50px;
  z-index: 2;
}

.blockquote-wrapper .author {
  position: relative;
  margin-left: 150px;
}

.blockquote-wrapper .author:first-letter {
  margin-left: -12px;
}

.form-label,
.form-check-label {
  font-weight: 700;
  font-size: 14px;
}

.form-control {
  border: 1px solid #dee2e6;
  font-size: 14px;
  line-height: 26px;
  background-color: #ffffff;
  border-radius: 6px;
  font-family: sans-serif;
}

.form-control:focus {
  border-color: #e05735;
  box-shadow: none;
}

.form-control[readonly] {
  background-color: #ffffff;
}

.form-control:disabled {
  background-color: #dee2e6;
}

.form-icon .icons {
  position: absolute;
  top: 9px;
  left: 18px;
}
.site-form-icon .site-icons::after {
  content: "https://";
  position: absolute;
  right: 0;
  left: 20px;
  top: 0;
}

.site-form-icon .site-icons2::after {
  content: "https://wa.me/";
  position: absolute;
  right: 0;
  left: 25px;
  top: 0;
  z-index:2;
}

.space-left {
  padding-left: 5.7rem !important;
}
.space-left2 {
  padding-left: 2.3rem !important;
}
.space-left3 {
  padding-left: 9.6rem !important;
}
.space-left4 {
  padding-left: 7.5rem !important;
}

.form-check-input {
  border: 1px solid #dee2e6;
}

.form-check-input:focus {
  border-color: #e05735;
  box-shadow: none;
}

.form-check-input.form-check-input:checked {
  background-color: #e05735;
  border-color: #e05735;
}

.map {
  line-height: 0;
}

.map iframe {
  width: 100%;
  height: 400px;
}

.subcribe-form .btn {
  padding: 10px 20px;
}

.subcribe-form input {
  padding: 12px 20px;
  width: 100%;
  color: #3c4858 !important;
  border: none;
  outline: none !important;
  padding-right: 160px;
  padding-left: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  height: 50px;
}

.subcribe-form button {
  position: absolute;
  top: 2px;
  right: 3px;
  outline: none !important;
}

.subcribe-form form {
  position: relative;
  max-width: 600px;
  margin: 0px auto;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table-responsive .table td,
.table-responsive .table th {
  vertical-align: middle;
}

.table-responsive .table.table-nowrap th,
.table-responsive .table.table-nowrap td {
  white-space: nowrap;
}

.table-responsive .table-center th {
  vertical-align: middle !important;
}

.table-responsive .table-center tbody tr:hover {
  color: #3c4858;
  background-color: #f5f5f7;
}

.table-responsive .table-center.invoice-tb th,
.table-responsive .table-center.invoice-tb td {
  text-align: end;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  z-index: 9999999;
}

#preloader #status {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#preloader #status .spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

#preloader #status .spinner .double-bounce1,
#preloader #status .spinner .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #e05735;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}

#preloader #status .spinner .double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.social-icon li a {
  color: #3c4858;
  border: 1px solid #3c4858;
  display: inline-block;
  height: 32px;
  width: 32px;
  line-height: 28px;
  text-align: center;
  transition: all 0.4s ease;
  overflow: hidden;
  position: relative;
}

.social-icon li a .fea-social {
  stroke-width: 2;
}

.social-icon li a:hover {
  background-color: #e05735;
  border-color: #e05735 !important;
  color: #ffffff !important;
}

.social-icon li a:hover .fea-social {
  fill: #e05735;
}

.social-icon.social li a {
  color: #adb5bd;
  border-color: #adb5bd;
}

.social-icon.foot-social-icon li a {
  color: #adb5bd;
  border-color: #283353;
}

.back-to-top {
  position: fixed;
  z-index: 99;
  bottom: 30px;
  right: 30px;
  display: none;
  transition: all 0.5s ease;
  height: 36px;
  width: 36px;
  line-height: 34px;
  border-radius: 6px;
  text-align: center;
  background: #e05735;
  color: #ffffff !important;
}

.back-to-top .icons {
  transition: all 0.5s ease;
}

.back-to-top:hover {
  transform: rotate(45deg);
}

.back-to-top:hover .icons {
  transform: rotate(-45deg) !important;
}

.back-to-home {
  position: fixed;
  top: 4%;
  right: 2%;
  z-index: 1;
}

.sticky-bar {
  position: sticky;
  top: 80px;
}

.fea {
  stroke-width: 1.8;
}

.fea.icon-sm {
  height: 16px;
  width: 16px;
}

.fea.icon-ex-md {
  height: 20px;
  width: 20px;
}

.fea.icon-m-md {
  height: 28px;
  width: 28px;
}

.fea.icon-md {
  height: 35px;
  width: 35px;
}

.fea.icon-lg {
  height: 42px;
  width: 42px;
}

.fea.icon-ex-lg {
  height: 52px;
  width: 52px;
}

[class^="uil-"]:before,
[class*=" uil-"]:before {
  margin: 0;
}

.uim-svg {
  vertical-align: 0 !important;
}

body {
  font-family: "axiforma", sans-serif;
  overflow-x: hidden !important;
  font-size: 15px;
  color: #161c2d;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "axiforma", sans-serif;
}

::selection {
  background: rgba(224, 87, 53, 0.9);
  color: #ffffff;
}

a {
  text-decoration: none !important;
}

p {
  line-height: 1.6;
}

#style-switcher .t-dark,
#style-switcher .t-rtl-light {
  display: inline-block;
}

#style-switcher .t-ltr-light,
#style-switcher .t-light,
#style-switcher .t-rtl-dark,
#style-switcher .t-ltr-dark {
  display: none;
}

/*********************************/
/*         Helpers               */
/*===============================*/
.section {
  padding: 100px 0;
  position: relative;
}

@media (max-width: 768px) {
  .section {
    padding: 60px 0;
  }
}

.bg-overlay {
  background-color: rgba(60, 72, 88, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.bg-overlay-white {
  background-color: rgba(255, 255, 255, 0.5);
}

.title-heading {
  line-height: 26px;
}

.title-heading .heading {
  font-size: 45px !important;
  letter-spacing: 1px;
}

@media (max-width: 768px) {
  .title-heading .heading {
    font-size: 35px !important;
  }
}

.title-heading .para-desc {
  font-size: 18px;
}

.section-title .title {
  letter-spacing: 0.5px;
  font-size: 30px !important;
}

@media (max-width: 768px) {
  .section-title .title {
    font-size: 24px !important;
  }
}

.para-desc {
  max-width: 600px;
}

.mt-100 {
  margin-top: 100px;
}

.shape {
  position: absolute;
  pointer-events: none;
  right: 0;
  bottom: -2px;
  left: 0;
}

.shape > svg {
  transform: scale(2);
  width: 100%;
  height: auto;
  transform-origin: top center;
}

.shape.integration-hero {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  height: 250px;
  background: #ffffff;
}

@media (max-width: 768px) {
  .shape.integration-hero {
    height: 140px;
  }
}

@media (max-width: 425px) {
  .shape.integration-hero {
    height: 60px;
  }
}

@media (max-width: 425px) {
  .shape {
    bottom: -4px;
  }
}

@media (min-width: 768px) {
  .margin-top-100 {
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  .mt-60 {
    margin-top: 60px;
  }
}

.page-wrapper {
  height: 100vh;
}

.page-wrapper.landrick-theme .sidebar-wrapper {
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-header,
.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-menu {
  border-top: 1px solid #e9ecef;
}

.page-wrapper.landrick-theme
  .sidebar-wrapper
  .sidebar-header
  .user-info
  .user-role,
.page-wrapper.landrick-theme
  .sidebar-wrapper
  .sidebar-header
  .user-info
  .user-status,
.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-brand > a,
.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-menu li a {
  color: #3c4858;
  font-weight: 600;
}

.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-menu li:hover > a,
.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-menu li.active > a,
.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-menu li:active > a,
.page-wrapper.landrick-theme
  .sidebar-wrapper
  .sidebar-menu
  li:active
  > a:before,
.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-menu li:hover > a:before,
.page-wrapper.landrick-theme
  .sidebar-wrapper
  .sidebar-menu
  li.active
  > a:before,
.page-wrapper.landrick-theme
  .sidebar-wrapper
  .sidebar-menu
  .sidebar-dropdown.active
  > a,
.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-header .user-info,
.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-brand > a:hover {
  color: #e05735 !important;
}

.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-brand .sidebar-colored {
  display: none;
}

.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-brand .logo-dark-mode {
  display: none;
}

.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-brand .logo-light-mode {
  display: inline-block;
}

.page-wrapper .sidebar-wrapper {
  width: 500px; /* 300px */
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -500px;
  z-index: 999;
}

@media (min-width: 1025px) {
  .page-wrapper .sidebar-wrapper {
    z-index: 1000;
  }
}

@media (max-width: 1024px) {
  .page-wrapper .sidebar-wrapper {
    left: 0;
  }
}

@media (max-width: 480px) {
  .page-wrapper .sidebar-wrapper {
    width: 100%;
  }
}

.page-wrapper .sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.page-wrapper .sidebar-wrapper a {
  text-decoration: none;
}

.page-wrapper .sidebar-wrapper .sidebar-content {
  max-height: calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;
}

.page-wrapper .sidebar-wrapper .sidebar-content.desktop {
  overflow-y: hidden;
}

.page-wrapper .sidebar-wrapper .sidebar-brand {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  height: 71px;
}

.page-wrapper .sidebar-wrapper .sidebar-brand > a {
  text-transform: uppercase;
  font-weight: bold;
  transition: all 0.3s ease;
}

.page-wrapper .sidebar-wrapper .sidebar-brand > a:focus {
  outline: none;
}

.page-wrapper .sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 24px;
  transition: all 0.3s ease;
}

.page-wrapper .sidebar-wrapper .sidebar-header {
  padding: 20px;
  overflow: hidden;
}

.page-wrapper .sidebar-wrapper .sidebar-menu {
  padding: 16px 0;
}

.page-wrapper .sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}

.page-wrapper .sidebar-wrapper .sidebar-menu li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 8px 24px 8px 20px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
  .page-wrapper .sidebar-wrapper .sidebar-menu li a {
    padding: 8px 24px 8px 24px;
  }
}

@media screen and (max-width: 425px) {
  .page-wrapper .sidebar-wrapper .sidebar-menu li a {
    padding: 8px 24px 8px 12px;
  }
}

.page-wrapper .sidebar-wrapper .sidebar-menu li a:hover > i::before {
  display: inline-block;
}

.page-wrapper .sidebar-wrapper .sidebar-menu li a:focus {
  outline: none;
}

.page-wrapper .sidebar-wrapper .sidebar-menu li a i {
  width: 36px;
  height: 36px;
  background-color: #f8f9fa;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.page-wrapper .sidebar-wrapper .sidebar-menu li:hover i,
.page-wrapper .sidebar-wrapper .sidebar-menu li.active i {
  background-color: #f2f4f6;
}

.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  content: "\F0142" !important;
  color: #3c4858;
  font-family: "Material Design Icons";
  font-size: 14px;
  position: absolute;
  right: 15px;
  top: 17px;
  transition: all 0.3s ease;
}

.page-wrapper
  .sidebar-wrapper
  .sidebar-menu
  .sidebar-dropdown.active
  > a:after {
  transform: rotate(90deg);
  right: 17px;
}

.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown:hover > a:after,
.page-wrapper
  .sidebar-wrapper
  .sidebar-menu
  .sidebar-dropdown.active
  > a:after {
  color: #e05735;
}

.page-wrapper
  .sidebar-wrapper
  .sidebar-menu
  .sidebar-dropdown
  .sidebar-submenu {
  display: none;
}

.page-wrapper
  .sidebar-wrapper
  .sidebar-menu
  .sidebar-dropdown
  .sidebar-submenu
  ul {
  padding: 5px 0;
}

.page-wrapper
  .sidebar-wrapper
  .sidebar-menu
  .sidebar-dropdown
  .sidebar-submenu
  ul
  li {
  padding-left: 25px;
  font-size: 14px;
}

.page-wrapper
  .sidebar-wrapper
  .sidebar-menu
  .sidebar-dropdown
  .sidebar-submenu
  ul
  li
  a:before {
  content: "\F09DF" !important;
  color: #3c4858;
  font-family: "Material Design Icons";
  margin-right: 4px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.page-wrapper
  .sidebar-wrapper
  .sidebar-menu
  .sidebar-dropdown
  .sidebar-submenu
  ul
  li
  a
  .badge,
.page-wrapper
  .sidebar-wrapper
  .sidebar-menu
  .sidebar-dropdown
  .sidebar-submenu
  ul
  li
  a
  .label {
  float: right;
  margin-top: 0px;
}

.page-wrapper .sidebar-wrapper .sidebar-menu ul li a span.label,
.page-wrapper .sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}

.page-wrapper .sidebar-wrapper.sidebar-dark {
  background-color: #161c2d;
}

.page-wrapper .sidebar-wrapper.sidebar-colored {
  background-color: #e05735;
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-brand .sidebar-colored,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-brand .sidebar-colored {
  display: inline-block;
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-brand .logo-dark-mode,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-brand .logo-dark-mode {
  display: none !important;
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-brand .logo-light-mode,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-brand .logo-light-mode {
  display: none !important;
}

.page-wrapper .sidebar-wrapper.sidebar-colored .btn.btn-icon,
.page-wrapper .sidebar-wrapper.sidebar-dark .btn.btn-icon {
  background-color: rgba(248, 249, 252, 0.03) !important;
  color: rgba(255, 255, 255, 0.5) !important;
  border: none !important;
  box-shadow: none;
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-header,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-header,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu {
  border-top-color: rgba(255, 255, 255, 0.03);
}

.page-wrapper
  .sidebar-wrapper.sidebar-colored
  .sidebar-header
  .user-info
  .user-role,
.page-wrapper
  .sidebar-wrapper.sidebar-colored
  .sidebar-header
  .user-info
  .user-status,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-brand > a,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li a,
.page-wrapper
  .sidebar-wrapper.sidebar-dark
  .sidebar-header
  .user-info
  .user-role,
.page-wrapper
  .sidebar-wrapper.sidebar-dark
  .sidebar-header
  .user-info
  .user-status,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-brand > a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li a {
  color: rgba(255, 255, 255, 0.5);
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li:hover > a,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li.active > a,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li:active > a,
.page-wrapper
  .sidebar-wrapper.sidebar-colored
  .sidebar-menu
  li:active
  > a:before,
.page-wrapper
  .sidebar-wrapper.sidebar-colored
  .sidebar-menu
  li:hover
  > a:before,
.page-wrapper
  .sidebar-wrapper.sidebar-colored
  .sidebar-menu
  li.active
  > a:before,
.page-wrapper
  .sidebar-wrapper.sidebar-colored
  .sidebar-menu
  .sidebar-dropdown.active
  > a,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-header .user-info,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-brand > a:hover,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li:hover > a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li.active > a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li:active > a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li:active > a:before,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li:hover > a:before,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li.active > a:before,
.page-wrapper
  .sidebar-wrapper.sidebar-dark
  .sidebar-menu
  .sidebar-dropdown.active
  > a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-header .user-info,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-brand > a:hover {
  color: #ffffff !important;
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li a i,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li a i {
  background-color: rgba(248, 249, 252, 0.03);
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li:hover i,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li.active i,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li:hover i,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li.active i {
  background-color: rgba(248, 249, 252, 0.05);
}

.page-wrapper
  .sidebar-wrapper.sidebar-colored
  .sidebar-menu
  .sidebar-dropdown
  > a:after,
.page-wrapper
  .sidebar-wrapper.sidebar-dark
  .sidebar-menu
  .sidebar-dropdown
  > a:after {
  color: rgba(248, 249, 252, 0.1);
}

.page-wrapper
  .sidebar-wrapper.sidebar-colored
  .sidebar-menu
  .sidebar-dropdown:hover
  > a:after,
.page-wrapper
  .sidebar-wrapper.sidebar-colored
  .sidebar-menu
  .sidebar-dropdown.active
  > a:after,
.page-wrapper
  .sidebar-wrapper.sidebar-dark
  .sidebar-menu
  .sidebar-dropdown:hover
  > a:after,
.page-wrapper
  .sidebar-wrapper.sidebar-dark
  .sidebar-menu
  .sidebar-dropdown.active
  > a:after {
  color: #ffffff;
}

.page-wrapper
  .sidebar-wrapper.sidebar-colored
  .sidebar-menu
  .sidebar-dropdown
  .sidebar-submenu
  ul
  li
  a:before,
.page-wrapper
  .sidebar-wrapper.sidebar-dark
  .sidebar-menu
  .sidebar-dropdown
  .sidebar-submenu
  ul
  li
  a:before {
  color: rgba(255, 255, 255, 0.5);
}

.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  padding-left: 0px;
  overflow-x: hidden;
  transition: all 0.3s ease;
}

.page-wrapper .page-content .top-header {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  max-height: 70px;
  left: 0px;
  transition: all 0.3s;
}

.page-wrapper .page-content .top-header .header-bar {
  background: #ffffff;
  height: 71px;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}

@media (max-width: 425px) {
  .page-wrapper .page-content .top-header .header-bar {
    padding: 24px 12px;
  }
}

.page-wrapper .page-content .top-header .header-bar .logo-icon {
  display: none;
}

@media (max-width: 1024px) {
  .page-wrapper .page-content .top-header .header-bar .logo-icon {
    display: block;
  }
}

.page-wrapper .page-content .top-header .header-bar .logo-icon .small {
  display: none;
}

@media (max-width: 767px) {
  .page-wrapper .page-content .top-header .header-bar .logo-icon .small {
    display: block;
  }
}

.page-wrapper .page-content .top-header .header-bar .logo-icon .big {
  display: none;
}

.page-wrapper
  .page-content
  .top-header
  .header-bar
  .logo-icon
  .big
  .logo-dark-mode {
  display: none;
}

.page-wrapper
  .page-content
  .top-header
  .header-bar
  .logo-icon
  .big
  .logo-light-mode {
  display: inline-block;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .page-wrapper .page-content .top-header .header-bar .logo-icon .big {
    display: block;
  }
}

.page-wrapper .page-content .top-header .header-bar .search-bar {
  padding-top: 14.5px;
  padding-bottom: 14.5px;
}

.page-wrapper
  .page-content
  .top-header
  .header-bar
  .search-bar
  .menu-search
  form {
  position: relative;
}

.page-wrapper
  .page-content
  .top-header
  .header-bar
  .search-bar
  .menu-search
  input[type="text"],
.page-wrapper
  .page-content
  .top-header
  .header-bar
  .search-bar
  .searchform
  input[type="text"] {
  box-shadow: none;
  padding: 10px 12px;
  height: 36px;
  font-size: 14px;
  display: block;
  width: 250px;
  outline: none !important;
  padding-right: 45px;
  opacity: 0.7;
}

.page-wrapper
  .page-content
  .top-header
  .header-bar
  .search-bar
  .menu-search
  input[type="submit"],
.page-wrapper
  .page-content
  .top-header
  .header-bar
  .search-bar
  .searchform
  input[type="submit"] {
  position: absolute;
  top: 5px;
  right: 10px;
  opacity: 0;
  width: 40px;
  height: 40px;
}

.page-wrapper
  .page-content
  .top-header
  .header-bar
  .search-bar
  .menu-search
  .searchform:after {
  content: "\F0349";
  position: absolute;
  font-family: "Material Design Icons";
  right: 14px;
  top: 10px;
  font-size: 20px;
  line-height: 20px;
  pointer-events: none;
}

.page-wrapper .page-content .layout-specing {
  padding: 94px 14px 24px !important;
  min-height: calc(100vh - 59px) !important;
  position: relative;
}

@media (max-width: 425px) {
  .page-wrapper .page-content .layout-specing {
    padding: 94px 0 24px !important;
  }
}

@media screen and (min-width: 1025px) {
  .page-wrapper.toggled .page-content {
    padding-left: 500px;
  }
}

.page-wrapper.toggled .top-header {
  left: 501px;
}

@media (max-width: 1024px) {
  .page-wrapper.toggled .top-header {
    left: 0;
  }
}

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}

@media (max-width: 1024px) {
  .page-wrapper.toggled .sidebar-wrapper {
    left: -502px;
  }
}

@media (max-width: 425px) {
  .page-wrapper.toggled .sidebar-wrapper {
    left: -100%;
  }
}

.sidebar-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 9px 24px !important;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  height: 58px;
}

@media (max-width: 425px) {
  .sidebar-footer {
    padding: 9px 12px !important;
  }
}

/*********************************/
/*         Home & Hero           */
/*===============================*/
.bg-home {
  height: 100vh;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

@media (max-width: 767px) {
  .bg-home {
    padding: 150px 0;
    height: auto;
  }
}

.bg-circle-gradiant {
  background: radial-gradient(
    circle at 50% 50%,
    rgba(224, 87, 53, 0.1) 0%,
    rgba(224, 87, 53, 0.1) 33.333%,
    rgba(224, 87, 53, 0.2) 33.333%,
    rgba(224, 87, 53, 0.2) 66.666%,
    rgba(224, 87, 53, 0.3) 66.666%,
    rgba(224, 87, 53, 0.3) 99.999%
  );
}

.features.feature-primary .title:hover {
  color: #e05735 !important;
}

.features.feature-primary .icon {
  color: #e05735 !important;
  background-color: rgba(224, 87, 53, 0.04) !important;
}

.features.feature-primary .link {
  color: #e05735 !important;
}

.features.feature-primary.key-feature .icon {
  background: linear-gradient(
    45deg,
    transparent,
    rgba(224, 87, 53, 0.1)
  ) !important;
}

.features.feature-bg-primary:hover {
  background-color: #e05735 !important;
}

.features.feature-secondary .title:hover {
  color: #5a6d90 !important;
}

.features.feature-secondary .icon {
  color: #5a6d90 !important;
  background-color: rgba(90, 109, 144, 0.04) !important;
}

.features.feature-secondary .link {
  color: #5a6d90 !important;
}

.features.feature-secondary.key-feature .icon {
  background: linear-gradient(
    45deg,
    transparent,
    rgba(90, 109, 144, 0.1)
  ) !important;
}

.features.feature-bg-secondary:hover {
  background-color: #5a6d90 !important;
}

.features.feature-success .title:hover {
  color: #2eca8b !important;
}

.features.feature-success .icon {
  color: #2eca8b !important;
  background-color: rgba(46, 202, 139, 0.04) !important;
}

.features.feature-success .link {
  color: #2eca8b !important;
}

.features.feature-success.key-feature .icon {
  background: linear-gradient(
    45deg,
    transparent,
    rgba(46, 202, 139, 0.1)
  ) !important;
}

.features.feature-bg-success:hover {
  background-color: #2eca8b !important;
}

.features.feature-warning .title:hover {
  color: #f17425 !important;
}

.features.feature-warning .icon {
  color: #f17425 !important;
  background-color: rgba(241, 116, 37, 0.04) !important;
}

.features.feature-warning .link {
  color: #f17425 !important;
}

.features.feature-warning.key-feature .icon {
  background: linear-gradient(
    45deg,
    transparent,
    rgba(241, 116, 37, 0.1)
  ) !important;
}

.features.feature-bg-warning:hover {
  background-color: #f17425 !important;
}

.features.feature-info .title:hover {
  color: #17a2b8 !important;
}

.features.feature-info .icon {
  color: #17a2b8 !important;
  background-color: rgba(23, 162, 184, 0.04) !important;
}

.features.feature-info .link {
  color: #17a2b8 !important;
}

.features.feature-info.key-feature .icon {
  background: linear-gradient(
    45deg,
    transparent,
    rgba(23, 162, 184, 0.1)
  ) !important;
}

.features.feature-bg-info:hover {
  background-color: #17a2b8 !important;
}

.features.feature-danger .title:hover {
  color: #e43f52 !important;
}

.features.feature-danger .icon {
  color: #e43f52 !important;
  background-color: rgba(228, 63, 82, 0.04) !important;
}

.features.feature-danger .link {
  color: #e43f52 !important;
}

.features.feature-danger.key-feature .icon {
  background: linear-gradient(
    45deg,
    transparent,
    rgba(228, 63, 82, 0.1)
  ) !important;
}

.features.feature-bg-danger:hover {
  background-color: #e43f52 !important;
}

.features.feature-dark .title:hover {
  color: #3c4858 !important;
}

.features.feature-dark .icon {
  color: #3c4858 !important;
  background-color: rgba(60, 72, 88, 0.04) !important;
}

.features.feature-dark .link {
  color: #3c4858 !important;
}

.features.feature-dark.key-feature .icon {
  background: linear-gradient(
    45deg,
    transparent,
    rgba(60, 72, 88, 0.1)
  ) !important;
}

.features.feature-bg-dark:hover {
  background-color: #3c4858 !important;
}

.features.feature-muted .title:hover {
  color: #8492a6 !important;
}

.features.feature-muted .icon {
  color: #8492a6 !important;
  background-color: rgba(132, 146, 166, 0.04) !important;
}

.features.feature-muted .link {
  color: #8492a6 !important;
}

.features.feature-muted.key-feature .icon {
  background: linear-gradient(
    45deg,
    transparent,
    rgba(132, 146, 166, 0.1)
  ) !important;
}

.features.feature-bg-muted:hover {
  background-color: #8492a6 !important;
}

.features.feature-light .title:hover {
  color: #f8f9fc !important;
}

.features.feature-light .icon {
  color: #f8f9fc !important;
  background-color: rgba(248, 249, 252, 0.04) !important;
}

.features.feature-light .link {
  color: #f8f9fc !important;
}

.features.feature-light.key-feature .icon {
  background: linear-gradient(
    45deg,
    transparent,
    rgba(248, 249, 252, 0.1)
  ) !important;
}

.features.feature-bg-light:hover {
  background-color: #f5f5f7 !important;
}

.features.feature-blue .title:hover {
  color: #e05735 !important;
}

.features.feature-blue .icon {
  color: #e05735 !important;
  background-color: rgba(224, 87, 53, 0.04) !important;
}

.features.feature-blue .link {
  color: #e05735 !important;
}

.features.feature-blue.key-feature .icon {
  background: linear-gradient(
    45deg,
    transparent,
    rgba(224, 87, 53, 0.1)
  ) !important;
}

.features.feature-bg-blue:hover {
  background-color: #e05735 !important;
}

.features.feature-footer .title:hover {
  color: #202942 !important;
}

.features.feature-footer .icon {
  color: #202942 !important;
  background-color: rgba(32, 41, 66, 0.04) !important;
}

.features.feature-footer .link {
  color: #202942 !important;
}

.features.feature-footer.key-feature .icon {
  background: linear-gradient(
    45deg,
    transparent,
    rgba(32, 41, 66, 0.1)
  ) !important;
}

.features.feature-bg-footer:hover {
  background-color: #202942 !important;
}

.features {
  transition: all 0.5s ease;
}

.features .icon {
  height: 65px;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.features .title {
  font-weight: 500;
}

.features .big-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  opacity: 0.05;
  font-size: 120px;
}

.features .titles,
.features .icons,
.features .para,
.features .big-icon {
  transition: all 0.5s ease;
}

.features:hover .titles,
.features:hover .icons,
.features:hover .big-icon {
  color: #ffffff !important;
}

.features:hover .para {
  color: rgba(255, 255, 255, 0.5) !important;
}

.key-feature .icon {
  height: 45px;
  width: 45px;
  line-height: 45px;
}

.chat .chat-list:hover,
.chat .chat-list.active,
.chat .chat-list:focus {
  background: #f8f9fc !important;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.chat .chat-list .on-off,
.chat .chat-type .on-off {
  position: absolute;
  left: -1px;
  top: -1px;
  font-size: 12px !important;
}

.chat.chat-person .on-off {
  font-size: 10px;
}

.chat.chat-person .chat-msg {
  margin-left: 8px;
}

.chat.chat-person .chat-msg .msg {
  border: 1px solid #e9ecef;
}

.chat.chat-person .chat-msg .msg-time {
  font-size: 12px !important;
}

.chat.chat-person .chat-right {
  justify-content: flex-end;
  text-align: right;
}

.chat.chat-person .chat-right .chat-user-image {
  order: 2;
}

.chat.chat-person .chat-right .chat-user-image .on-off {
  right: -1px;
}

.chat.chat-person .chat-right .chat-msg {
  margin-right: 8px;
  margin-left: 0;
}

.chat.chat-person .chat-right .chat-msg .msg {
  display: inline-block;
}

.chat.chat-person .chat-right .chat-msg .msg-time {
  display: block;
}

/*********************************/
/*         Testimonial           */
/*===============================*/
.tns-nav {
  text-align: center;
  margin-top: 10px;
}

.tns-nav button {
  border-radius: 3px;
  background: rgba(224, 87, 53, 0.5) !important;
  transition: all 0.5s ease;
  border: none;
  margin: 0 5px;
  padding: 5px;
}

.tns-nav button.tns-nav-active {
  background: #e05735 !important;
  transform: rotate(45deg);
}

/*********************************/
/*         Blog & News           */
/*===============================*/
.blog.blog-primary .tag {
  background: #e05735 !important;
}

.blog.blog-primary .title:hover {
  color: #e05735 !important;
}

.blog.blog-primary .link {
  color: #e05735 !important;
}

.blog.blog-primary .post-meta .like:hover,
.blog.blog-primary .post-meta .comments:hover,
.blog.blog-primary .post-meta .readmore:hover {
  color: #e05735 !important;
}

.blog.blog-secondary .tag {
  background: #5a6d90 !important;
}

.blog.blog-secondary .title:hover {
  color: #5a6d90 !important;
}

.blog.blog-secondary .link {
  color: #5a6d90 !important;
}

.blog.blog-secondary .post-meta .like:hover,
.blog.blog-secondary .post-meta .comments:hover,
.blog.blog-secondary .post-meta .readmore:hover {
  color: #5a6d90 !important;
}

.blog.blog-success .tag {
  background: #2eca8b !important;
}

.blog.blog-success .title:hover {
  color: #2eca8b !important;
}

.blog.blog-success .link {
  color: #2eca8b !important;
}

.blog.blog-success .post-meta .like:hover,
.blog.blog-success .post-meta .comments:hover,
.blog.blog-success .post-meta .readmore:hover {
  color: #2eca8b !important;
}

.blog.blog-warning .tag {
  background: #f17425 !important;
}

.blog.blog-warning .title:hover {
  color: #f17425 !important;
}

.blog.blog-warning .link {
  color: #f17425 !important;
}

.blog.blog-warning .post-meta .like:hover,
.blog.blog-warning .post-meta .comments:hover,
.blog.blog-warning .post-meta .readmore:hover {
  color: #f17425 !important;
}

.blog.blog-info .tag {
  background: #17a2b8 !important;
}

.blog.blog-info .title:hover {
  color: #17a2b8 !important;
}

.blog.blog-info .link {
  color: #17a2b8 !important;
}

.blog.blog-info .post-meta .like:hover,
.blog.blog-info .post-meta .comments:hover,
.blog.blog-info .post-meta .readmore:hover {
  color: #17a2b8 !important;
}

.blog.blog-danger .tag {
  background: #e43f52 !important;
}

.blog.blog-danger .title:hover {
  color: #e43f52 !important;
}

.blog.blog-danger .link {
  color: #e43f52 !important;
}

.blog.blog-danger .post-meta .like:hover,
.blog.blog-danger .post-meta .comments:hover,
.blog.blog-danger .post-meta .readmore:hover {
  color: #e43f52 !important;
}

.blog.blog-dark .tag {
  background: #3c4858 !important;
}

.blog.blog-dark .title:hover {
  color: #3c4858 !important;
}

.blog.blog-dark .link {
  color: #3c4858 !important;
}

.blog.blog-dark .post-meta .like:hover,
.blog.blog-dark .post-meta .comments:hover,
.blog.blog-dark .post-meta .readmore:hover {
  color: #3c4858 !important;
}

.blog.blog-muted .tag {
  background: #8492a6 !important;
}

.blog.blog-muted .title:hover {
  color: #8492a6 !important;
}

.blog.blog-muted .link {
  color: #8492a6 !important;
}

.blog.blog-muted .post-meta .like:hover,
.blog.blog-muted .post-meta .comments:hover,
.blog.blog-muted .post-meta .readmore:hover {
  color: #8492a6 !important;
}

.blog.blog-light .tag {
  background: #f8f9fc !important;
}

.blog.blog-light .title:hover {
  color: #f8f9fc !important;
}

.blog.blog-light .link {
  color: #f8f9fc !important;
}

.blog.blog-light .post-meta .like:hover,
.blog.blog-light .post-meta .comments:hover,
.blog.blog-light .post-meta .readmore:hover {
  color: #f8f9fc !important;
}

.blog.blog-blue .tag {
  background: #e05735 !important;
}

.blog.blog-blue .title:hover {
  color: #e05735 !important;
}

.blog.blog-blue .link {
  color: #e05735 !important;
}

.blog.blog-blue .post-meta .like:hover,
.blog.blog-blue .post-meta .comments:hover,
.blog.blog-blue .post-meta .readmore:hover {
  color: #e05735 !important;
}

.blog.blog-footer .tag {
  background: #202942 !important;
}

.blog.blog-footer .title:hover {
  color: #202942 !important;
}

.blog.blog-footer .link {
  color: #202942 !important;
}

.blog.blog-footer .post-meta .like:hover,
.blog.blog-footer .post-meta .comments:hover,
.blog.blog-footer .post-meta .readmore:hover {
  color: #202942 !important;
}

.blog {
  transition: all 0.3s ease;
}

.blog .content h4 {
  line-height: 1.2;
}

.blog .content .title {
  transition: all 0.5s ease;
}

.blog .content .post-meta .like,
.blog .content .post-meta .comments,
.blog .content .post-meta .readmore {
  font-size: 15px;
  transition: all 0.5s ease;
}

.blog .overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease;
  background: #3c4858;
}

.blog .author {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: all 0.5s ease;
  top: 5%;
  left: 5%;
}

.blog.blog-detail:hover {
  transform: translateY(0px) !important;
}

.blog:hover {
  transform: translateY(-10px);
}

.blog:hover .overlay {
  opacity: 0.4;
}

.blog:hover .author {
  opacity: 1;
}

.sidebar .widget .post-recent {
  padding-bottom: 15px;
}

.sidebar .widget .post-recent:last-child {
  padding-bottom: 0;
}

.sidebar .widget .post-recent .post-recent-thumb {
  width: 25%;
}

.sidebar .widget .post-recent .post-recent-content {
  width: 75%;
  padding-left: 10px;
}

.sidebar .widget .post-recent .post-recent-content a {
  display: block;
  color: #3c4858;
  font-size: 15px;
  transition: all 0.5s ease;
}

.sidebar .widget .post-recent .post-recent-content a:hover {
  color: #e05735;
}

.sidebar .widget .post-recent .post-recent-content span {
  font-size: 13px;
}

.tagcloud > a {
  background: #e9ecef;
  color: #3c4858;
  display: inline-block;
  font-size: 9px;
  letter-spacing: 1px;
  margin: 5px 10px 5px 0;
  padding: 8px 12px 7px;
  text-transform: uppercase;
  transition: all 0.5s ease;
  font-weight: 600;
}

.tagcloud > a:hover {
  background: #e05735;
  color: #ffffff;
}

.media-list .media .media-heading:hover {
  color: #e05735 !important;
}

.media-list .sub-comment {
  border-left: 2px dashed #dee2e6;
}

.widget-grid .item {
  position: relative;
  width: 33.33333333%;
  padding-top: 33.33333333%;
  float: left;
}

.widget-grid .item a {
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  overflow: hidden;
}

.widget-grid .item:first-child {
  width: 66.66666666%;
  padding-top: 66.66666666%;
}

.widget-grid .item:nth-child(4) {
  clear: both;
}

.fc-event-title-container,
#external-events .fc-event {
  cursor: pointer;
  margin: 4px 2px;
}

#external-events .fc-event .fc-event-main {
  padding: 5px;
}

.fc .fc-non-business {
  background-color: transparent;
}

.fc .fc-daygrid-day-number {
  padding: 5px;
  font-weight: 600;
  color: #e05735;
}

.fc .fc-col-header-cell-cushion {
  padding: 8px 4px !important;
  color: #e05735;
}

.fc .fc-scrollgrid-liquid {
  border-radius: 5px !important;
}

.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #e9ecef !important;
}

.fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
  font-size: 18px !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start {
  margin: auto 8px 4px;
}

.fc-event-main {
  background-color: #e05735;
}

.fc-daygrid-dot-event {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
}

.fc-daygrid-dot-event:hover {
  background-color: #e9ecef;
}

.fc .fc-button {
  padding: 4px 8px;
  font-size: 13px !important;
  background-color: #e05735 !important;
  border: 1px solid #e05735 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.fc .fc-button:disabled,
.fc .fc-button:disabled:hover,
.fc .fc-button:disabled:focus,
.fc .fc-button:disabled:active,
.fc .fc-button:disabled.active,
.fc .fc-button:disabled.focus {
  background-color: rgba(224, 87, 53, 0.1) !important;
  border: 1px solid rgba(224, 87, 53, 0.1) !important;
  color: #e05735 !important;
}

.fc .fc-button:hover,
.fc .fc-button:focus,
.fc .fc-button:active,
.fc .fc-button.active,
.fc .fc-button.focus {
  background-color: #cf5132 !important;
  border-color: #cf5132 !important;
}

.fc .fc-button:focus {
  box-shadow: none !important;
}

.fc .fc-icon {
  font-size: 16px !important;
}

#calendar {
  max-width: 1240px;
}

.shop-features .category-title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  z-index: 1;
}

.shop-list .shop-image .overlay-work,
.shop-list .shop-image .shop-icons {
  position: absolute;
  opacity: 0;
  transition: all 0.5s ease;
}

.shop-list .shop-image .overlay-work {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.shop-list .shop-image .overlay-work .out-stock {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.shop-list .shop-image .shop-icons {
  top: 15px;
  right: 15px;
  z-index: 2;
}

.shop-list .shop-image:hover .overlay-work,
.shop-list .shop-image:hover .shop-icons {
  opacity: 1;
}

.shop-list .content .product-name {
  transition: all 0.5s ease;
}

.shop-list .content .product-name:hover {
  color: #e05735 !important;
}

.shop-list .label {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
}

.shop-list .qty-btn {
  pointer-events: none;
  width: 65px;
  padding-left: 15px;
}

/*********************************/
/*         Pricing & Plans       */
/*===============================*/
.pricing-rates {
  position: relative;
  z-index: 1;
  border-bottom: 3px solid #adb5bd !important;
  transition: all 0.5s ease;
}

.pricing-rates .price {
  font-weight: 400;
}

.pricing-rates.business-rate:hover {
  transform: scale(1.03);
  z-index: 2;
  border-color: #e05735 !important;
  box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
  background-color: #ffffff !important;
}

.pricing-rates.business-rate:hover .title {
  color: #e05735;
}

.ribbon.ribbon-primary span {
  background-color: #e05735 !important;
}

.ribbon.ribbon-primary span:before {
  border-left-color: #2443ac;
  border-top-color: #2443ac;
}

.ribbon.ribbon-primary span:after {
  border-right-color: #2443ac;
  border-top-color: #2443ac;
}

.ribbon.ribbon-secondary span {
  background-color: #5a6d90 !important;
}

.ribbon.ribbon-secondary span:before {
  border-left-color: #465571;
  border-top-color: #465571;
}

.ribbon.ribbon-secondary span:after {
  border-right-color: #465571;
  border-top-color: #465571;
}

.ribbon.ribbon-success span {
  background-color: #2eca8b !important;
}

.ribbon.ribbon-success span:before {
  border-left-color: #25a06e;
  border-top-color: #25a06e;
}

.ribbon.ribbon-success span:after {
  border-right-color: #25a06e;
  border-top-color: #25a06e;
}

.ribbon.ribbon-warning span {
  background-color: #f17425 !important;
}

.ribbon.ribbon-warning span:before {
  border-left-color: #d55b0e;
  border-top-color: #d55b0e;
}

.ribbon.ribbon-warning span:after {
  border-right-color: #d55b0e;
  border-top-color: #d55b0e;
}

.ribbon.ribbon-info span {
  background-color: #17a2b8 !important;
}

.ribbon.ribbon-info span:before {
  border-left-color: #117a8b;
  border-top-color: #117a8b;
}

.ribbon.ribbon-info span:after {
  border-right-color: #117a8b;
  border-top-color: #117a8b;
}

.ribbon.ribbon-danger span {
  background-color: #e43f52 !important;
}

.ribbon.ribbon-danger span:before {
  border-left-color: #d21e32;
  border-top-color: #d21e32;
}

.ribbon.ribbon-danger span:after {
  border-right-color: #d21e32;
  border-top-color: #d21e32;
}

.ribbon.ribbon-dark span {
  background-color: #3c4858 !important;
}

.ribbon.ribbon-dark span:before {
  border-left-color: #272f3a;
  border-top-color: #272f3a;
}

.ribbon.ribbon-dark span:after {
  border-right-color: #272f3a;
  border-top-color: #272f3a;
}

.ribbon.ribbon-muted span {
  background-color: #8492a6 !important;
}

.ribbon.ribbon-muted span:before {
  border-left-color: #68788f;
  border-top-color: #68788f;
}

.ribbon.ribbon-muted span:after {
  border-right-color: #68788f;
  border-top-color: #68788f;
}

.ribbon.ribbon-light span {
  background-color: #f5f5f7 !important;
}

.ribbon.ribbon-light span:before {
  border-left-color: #d4daed;
  border-top-color: #d4daed;
}

.ribbon.ribbon-light span:after {
  border-right-color: #d4daed;
  border-top-color: #d4daed;
}

.ribbon.ribbon-blue span {
  background-color: #e05735 !important;
}

.ribbon.ribbon-blue span:before {
  border-left-color: #2443ac;
  border-top-color: #2443ac;
}

.ribbon.ribbon-blue span:after {
  border-right-color: #2443ac;
  border-top-color: #2443ac;
}

.ribbon.ribbon-footer span {
  background-color: #202942 !important;
}

.ribbon.ribbon-footer span:before {
  border-left-color: #0f131f;
  border-top-color: #0f131f;
}

.ribbon.ribbon-footer span:after {
  border-right-color: #0f131f;
  border-top-color: #0f131f;
}

.ribbon {
  position: absolute;
  top: -5px;
  width: 75px;
  height: 75px;
  z-index: 2;
}

.ribbon.ribbon-right {
  right: -5px;
}

.ribbon.ribbon-right span {
  right: -23px;
  transform: rotate(45deg);
}

.ribbon.ribbon-left {
  left: -5px;
}

.ribbon.ribbon-left span {
  left: -23px;
  transform: rotate(-45deg);
}

.ribbon span {
  position: absolute;
  top: 18px;
  width: 100px;
  color: #ffffff;
}

.ribbon span:before,
.ribbon span:after {
  content: "";
  position: absolute;
  top: 100%;
  z-index: -1;
  border: 3px solid transparent;
}

.ribbon span:before {
  left: 0;
}

.ribbon span:after {
  right: 0;
}

.invoice-top .logo-invoice {
  font-weight: 700;
  font-size: 27px;
  letter-spacing: 1px;
}

.switcher-pricing .toggle,
.switcher-pricing .toggler {
  display: inline-block !important;
  vertical-align: middle;
}

.switcher-pricing .toggler {
  transition: all 0.5s ease;
  font-weight: bold;
}

.switcher-pricing .toggler--is-active {
  color: #e05735 !important;
}

.switcher-pricing .form-switch .form-check-input {
  width: 48px;
  height: 24px;
  margin-top: 0;
}

.hide {
  display: none;
}

.apexcharts-legend-text {
  font-family: "axiforma", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

/*********************************/
/*         Authentications       */
/*===============================*/
.login-page {
  font-size: 15px;
}

.login-page .forgot-pass {
  z-index: 99;
  position: relative;
  font-size: 13px;
}

.cover-user .container-fluid {
  max-width: 100%;
}

@media (max-width: 768px) {
  .cover-user .container-fluid .padding-less {
    position: relative !important;
    height: 400px !important;
  }
}

.cover-user .container-fluid .padding-less.img {
  background: scroll center no-repeat;
  background-size: cover;
  position: absolute;
  height: 100%;
}

.cover-user .cover-user-img {
  height: 100vh;
}

@media (min-width: 1025px) {
  .cover-user .cover-user-img {
    padding: 0px 20%;
  }
}

@media (max-width: 768px) {
  .cover-user .cover-user-img {
    align-items: start !important;
    height: auto !important;
    padding: 30px;
  }
}

@media (max-width: 768px) {
  .cover-user .cover-user-content {
    text-align: left !important;
  }
  .cover-user .cover-my-30 {
    margin: 30px 0;
  }
}

.form-signin {
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.avatar-profile {
  position: relative;
  top: -80px;
}

.simplebar-track.simplebar-vertical {
  width: 8px !important;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  width: 4px !important;
  background: #adb5bd !important;
}

/*********************************/
/*         Works & Portfolios    */
/*===============================*/
.work-container.work-classic .work-image {
  transition: all 0.5s ease;
}

.work-container.work-classic .work-image:hover {
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.work-container.work-classic .content .title,
.work-container.work-modern .content .title {
  font-size: 18px;
  transition: all 0.5s ease;
}

.work-container.work-classic .content .title:hover,
.work-container.work-modern .content .title:hover {
  color: #e05735 !important;
}

.work-container.work-classic .content .tag,
.work-container.work-modern .content .tag {
  font-size: 15px !important;
}

.work-container.work-modern img {
  transition: all 0.5s ease;
}

.work-container.work-modern:hover img {
  transform: scale(1.1) rotate(3deg);
}

.tobii__btn svg {
  height: 30px;
  width: auto;
}

.tobii__counter {
  font-size: 16px;
}

.tobii-zoom {
  display: block !important;
}

.tobii-zoom__icon {
  display: none;
}

#grid {
  padding: 0 !important;
}

footer {
  padding: 60px 0 24px;
  position: relative;
  color: #adb5bd;
}

footer .text-foot {
  color: #adb5bd;
}

footer .footer-head {
  font-size: 18px !important;
  letter-spacing: 1px;
  font-weight: 500;
}

footer .footer-list {
  margin-bottom: 0;
}

footer .footer-list li {
  margin-bottom: 10px;
}

footer .footer-list li a {
  transition: all 0.5s ease;
}

footer .footer-list li a:hover {
  color: #e6e8ea;
}

footer .footer-list li:last-child {
  margin-bottom: 0;
}

footer .footer-bar,
footer.footer-bar {
  border-top: 1px solid #2a3656;
}

/* custom scroll css start */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f8f9fc;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*# sourceMappingURL=style.css.map */
