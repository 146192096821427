/* ============================
    iTechNotion Pvt. Ltd.
==============================*/

/* Common css start */
.shadow-n {
  box-shadow: none !important;
}
.border-primary {
  border: solid 1px #e05735 !important;
}
.bg-light-blue {
  background-color: #eaf7ff !important;
}
.small-s {
  font-size: 0.8em;
}
.aw-16 {
  width: 16px;
}
.aw-20 {
  width: 20px;
}
.fs-16 {
  font-size: 16px;
}
.fs-15{
  font-size: 15px;
}
.fs-24 {
  font-size: 24px;
}
.avatar-ex-xs {
  height: 20px;
  width: 20px;
}
.text-right {
  text-align: right;
}
.btn.btn-icon.btn-xs {
  height: 20px;
  width: 20px;
  line-height: 18px;
}
.mt-n10 {
  margin-top: -10px;
}
.btn-secondary2 {
  background-color: #0e3247 !important;
  border: 1px solid #0e3247 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.1);
}

.btn-secondary2:hover,
.btn-secondary2:focus,
.btn-secondary2:active,
.btn-secondary2.active,
.btn-secondary2.focus {
  background-color: #0e3247 !important;
  border-color: #0e3247 !important;
  color: #ffffff !important;
}
/* Common css end */
.navigation-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.navigation-menu > li {
  float: left;
  display: block;
  position: relative;
  margin: 0 5px;
  margin-left: 6px;
}
.navigation-menu li a {
  padding-top: 25px;
  padding-bottom: 25px;
  min-height: 62px;
  display: block;
  color: #d0d1d2;
  font-size: 13px;
  background-color: transparent !important;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 24px;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
}
.navigation-menu li a:hover,
.navigation-menu li.active a {
  color: #11263c;
}
.more-card {
  background: #f6f6f6;
  border: 1px dashed #cecece;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  height: 100%;
}
.more-card > p {
  margin-top: 15px;
  font-weight: 600;
}
.color_active {
  border-radius: 5px;
  padding: 1px;
  border: solid 1px #0e3247 !important;
  margin-bottom: 15px;
}

/* edit card overlay */
.main-card {
  position: relative;
}

.edit-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: 0.1s ease;
  background: rgba(0, 0, 0, 0.33);
}

.card-img:hover .edit-overlay {
  opacity: 1;
  visibility: visible;
}
.card-img-size {
  height: 20%;
}
.pointer-n:hover .edit-overlay {
  position: relative;
  z-index: 99;
  opacity: 0;
  pointer-events: none;
}
.edit-overlay .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* upload input profile start */
.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #9ca1ad;
  font-size: 14px;
  padding: 10px 12px;
  background-color: transparent;
  border: 1px dashed #e0e2e7;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 18px;
  margin-right: 4px;
}
/* upload input profile end */

/* template edit */

.template-edit {
  margin-bottom: 10px;
}
.template-edit.active {
  position: relative;
  color: #e05735;
  font-weight: 700;
}
.template-edit.active::before {
  content: "";
  width: 30px;
  height: 2px;
  background-color: #e05735;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
.label_a1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #0e3147;
  margin-bottom: 10px;
}
.accordion-button:not(.collapsed) {
  color: #e05735;
}

/* cancle button css */
.profile {
  position: relative;
}
.profile_cancel {
  position: absolute;
  top: -12px;
  right: -6px;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease;
}
.profile:hover .profile_cancel {
  visibility: visible;
  opacity: 1;
}

/* grid list css */
.grid_list {
  border-radius: 5px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.grid_list a {
  text-decoration: none;
  color: #000;
  font-size: 20px;
}
.grid_list.active {
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}
/* help button css */
.btn-help {
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: block;
}

/* custom dropdown */
.custom-dropdown {
  position: relative;
  cursor: pointer;
}
.sub-dropdown {
  position: absolute;
  left: 190px;
  top: 15px;
  margin: 0px;
  background: #fff;
  box-shadow: 0 0 3px rgb(60, 72, 88, 0.15);
  padding: 5px;
  visibility: hidden;
  opacity: 0;
}
.open {
  visibility: visible;
  opacity: 1;
}

/* ====================================== Media Query ============================================== */

@media screen and (max-width: 767px) {
  .sub-dropdown {
    width: 100%;
    left: 0px;
    top: 30px;
  }
}
/* =======================================Custom Pagination===================================== */
.pageNumbers {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.pageNumbers li {
  margin: 7px;
  cursor: pointer;
}

.pageNumbers li.active {
  background-color: #e05735;
  border-color: #e05735;
  color: #fff;
}

.pageNumbers li button {
  background-color: transparent;
  border: 1px solid grey;
  padding: 6px 12px;
  color: #e05735;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}
.pageNumbers li button:hover {
  background-color: #e05735;
  border-color: #e05735;
  color: #fff !important;
}
.pageNumbers li button:focus {
  outline: none;
}
.pageNumbers li button:disabled {
  color: rgb(61, 60, 60);
}
.custom-bullets {
  padding: 6px 12px !important;
  border: 1px solid grey;
  cursor: pointer;
  margin: 10px;
  border-radius: 5px;
}
@media only screen and (max-width: 600px) {
  .pageNumbers {
    padding: 0px;
  }
}

/* =========================Vcard=============================== */

/* vCrad Mobile */
.main {
  display: flex;
  justify-content: center;
}
.mobile-frame {
  /* flex: 1; */
  height: 100%;
  /* background-color: #f9fafc; */
  overflow: auto;
  justify-content: center;
}

/* ============================
    iTechNotion Pvt. Ltd.
==============================*/

/* Common css start */
.shadow-n {
  box-shadow: none !important;
}
.border-primary {
  border: solid 1px #e05735 !important;
}
.bg-light-blue {
  background-color: #eaf7ff !important;
}
.small-s {
  font-size: 0.8em;
}
.fs-12 {
  font-size: 12px;
}
.fs-16 {
  font-size: 16px;
}
.fs-24 {
  font-size: 24px;
}
.avatar-ex-xs {
  height: 20px;
  width: 20px;
}
.text-right {
  text-align: right;
}
.btn.btn-icon.btn-xs {
  height: 20px;
  width: 20px;
  line-height: 18px;
}
/* Common css end */
.navigation-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.navigation-menu > li {
  float: left;
  display: block;
  position: relative;
  margin: 0 5px;
  margin-left: 6px;
}
.navigation-menu li a {
  padding-top: 25px;
  padding-bottom: 25px;
  min-height: 62px;
  display: block;
  color: #d0d1d2;
  font-size: 13px;
  background-color: transparent !important;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 24px;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
}
.navigation-menu li a:hover,
.navigation-menu li.active a {
  color: #11263c;
}
.more-card {
  background: #f6f6f6;
  border: 1px dashed #cecece;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  height: 100%;
}
.more-card > p {
  margin-top: 15px;
  font-weight: 600;
}

/*====================== edit card overlay================================ */
.main-card {
  position: relative;
}

.edit-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: 0.1s ease;
  background: rgba(0, 0, 0, 0.33);
}

.card-img:hover .edit-overlay {
  opacity: 1;
  visibility: visible;
}
.pointer-n:hover .edit-overlay {
  position: relative;
  z-index: 99;
  opacity: 0;
  pointer-events: none;
}
.edit-overlay .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.border-light {
  border: solid 2px #fff;
}
.border-dark {
  border: solid 2px #000;
}
.card-shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

/*================ Custom LINK CSS start ==================*/
.mobile-body {
  width: 352px;
  height: 700px;
  /* border: 6px solid #000000; */
  border-radius: 40px;
  overflow: hidden;
  background-color: #0e3247;
  /* box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 30%); */
  box-shadow: 2px 4px 16px rgb(0 0 0 / 16%);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
}
.mobile-body .mobile-wrap {
  height: 100%;
  overflow-y: scroll;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.mobile-body .mobile-wrap::-webkit-scrollbar {
  display: none;
}
.custom_link{
  max-width: 420px;
}
.custom_link .custom_link_icon{
  display: inline-flex;
  justify-content: center;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 15px;
  background-color: #fff;
  text-align: center;
  margin-top: 50px;
}
.custom_link .custom_link_icon a{
  font-size: 30px;
  transform: rotate(150deg);
}
.custom_link h5{
  color: #fff;
  margin-top: 10px;
}
.custom_url{
  max-width: 300px;
  padding: 0 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}

@media screen and (max-width: 380px) {
  .mobile-body {
    width: 320px;
    height: 636px;
  }
}

@media screen and (max-width: 330px) {
  .mobile-body {
    width: 310px;
    height: 606px;
  }
}

/*============== upload input profile start==================== */
.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #9ca1ad;
  font-size: 14px;
  padding: 10px 12px;
  background-color: transparent;
  border: 1px dashed #e0e2e7;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 18px;
  margin-right: 4px;
}
/* upload input profile end */

/*=================== template edit======================= */

.template-edit {
  margin-bottom: 10px;
}
.template-edit.active {
  position: relative;
  color: #e05735;
  font-weight: 700;
}
.template-edit.active::before {
  content: "";
  width: 30px;
  height: 2px;
  background-color: #e05735;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
.label_a1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #0e3147;
  margin-bottom: 10px;
}
.accordion-button:not(.collapsed) {
  color: #e05735;
}

/*========================= cancle button css===================== */
.profile {
  position: relative;
}
.profile_cancel {
  position: absolute;
  top: -12px;
  right: -6px;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease;
}
.profile:hover .profile_cancel {
  visibility: visible;
  opacity: 1;
}
.profile_width {
  width: 90px;
}

/* grid list css */
.grid_list {
  border-radius: 5px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.grid_list a {
  text-decoration: none;
  color: #000;
  font-size: 20px;
}
.grid_list.active {
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}
/* help button css */
.btn-help {
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: block;
}

/* custom dropdown */
.custom-dropdown {
  position: relative;
}
.sub-dropdown {
  position: absolute;
  left: 190px;
  top: 15px;
  margin: 0px;
  background: #fff;
  box-shadow: 0 0 3px rgb(60, 72, 88, 0.15);
  padding: 5px;
  visibility: hidden;
  opacity: 0;
}
.open {
  visibility: visible;
  opacity: 1;
}

/* ====================================== Media Query ============================================== */

@media screen and (max-width: 767px) {
  .sub-dropdown {
    width: 100%;
    left: 0px;
    top: 30px;
  }
}
@media screen and (max-width: 359px) {
  .from-check-label-res {
    font-size: 12px;
  }
}

/*====================== vCrad Mobile ===============================*/
.main {
  display: flex;
  justify-content: center;
}
.mobile-frame {
  /* flex: 1; */
  height: 100%;
  /* background-color: #f9fafc; */
  overflow: inherit;
  justify-content: center;
}

/*================= custom dropdown2=======================30/12/2021 */
.select-category {
  position: relative;
  margin-bottom: 15px;
  width: 100%;
}
.select-category .selectBtn {
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #dee2e6;
  font-size: 14px;
  line-height: 26px;
  background-color: #ffffff;
  border-radius: 6px;
}
.select-category .selectBtn:after {
  content: "";
  position: absolute;
  top: 45%;
  right: 15px;
  width: 6px;
  height: 6px;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  border-right: 2px solid #666;
  border-bottom: 2px solid #666;
  transition: 0.2s ease;
}
.select-category .selectBtn.toggle {
  border-radius: 3px 3px 0 0;
}
.select-category .selectBtn.toggle:after {
  -webkit-transform: translateY(-50%) rotate(-135deg);
  transform: translateY(-50%) rotate(-135deg);
}
.select-category .selectDropdown {
  position: absolute;
  top: 100%;
  width: 100%;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
  background: var(--bg1);
  border-top: 1px solid #eee;
  z-index: 1;
  background: #fff;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  visibility: hidden;
  transition: 0.2s ease;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}
.select-category .selectDropdown .option {
  padding: 10px 15px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.select-category .selectDropdown .option span {
  color: #e05735;
}
.select-category .selectDropdown .option:hover {
  background: #f8f8f8;
}
.select-category .selectDropdown.toggle {
  visibility: visible;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

/* published || Draft || Deactive css */
/* grid */
.published-text {
  color: #30b746;
}
.draft-text {
  color: #e9d950;
}
.deactive-text {
  color: #d0d1d2;
}
.published {
  border-bottom: solid 6px #30b746;
}
.published ~ .published-txt {
  text-align: center;
  color: #30b746;
  margin: 5px 0;
}
.draft {
  border-bottom: solid 6px #e9d950;
}
.draft ~ .draft-txt {
  text-align: center;
  color: #e9d950;
  margin: 5px 0;
}
.deactive {
  border-bottom: solid 6px #d0d1d2;
  filter: grayscale(1);
  pointer-events: none;
  cursor: not-allowed;
}
.deactive ~ .deactive-txt {
  text-align: center;
  color: #d0d1d2;
  margin: 5px 0;
}

/* list */
.published-list {
  border-left: solid 6px #30b746;
}
.published-list ~ .published-txt {
  text-align: center;
  color: #30b746;
  margin: 5px 0;
}
.draft-list {
  border-left: solid 6px #e9d950;
}
.draft-list ~ .draft-txt {
  text-align: center;
  color: #e9d950;
  margin: 5px 0;
}
.deactive-list {
  border-left: solid 6px #d0d1d2;
  filter: grayscale(1);
  pointer-events: none;
  cursor: not-allowed;
}
.deactive-list ~ .deactive-txt {
  text-align: center;
  color: #d0d1d2;
  margin: 5px 0;
}

.hideMobileBody {
  border: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  width: 100% !important;
  height: inherit !important;
}
.h_vh-100 {
  height: 100vh !important;
}

.dropDownInput {
  height: 60px;
}
a.disabled {
  pointer-events: none;
  cursor: default;
}

.carousel-inner .carousel-item-end.active,
.carousel-inner .carousel-item-next {
  transform: translateX(25%);
}

.carousel-inner .carousel-item-start.active,
.carousel-inner .carousel-item-prev {
  transform: translateX(-25%);
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
  transform: translateX(0);
}
/* business folder list css start */
.business-folder ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  margin: 10px 0;
}
.business-folder ul li {
  list-style: none;
  margin: 5px 10px 15px;
  background: #0e3247;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px 10px;
  border-radius: 12px;
}
.business-folder ul li.active {
  background-color: #ffaa00;
}
.business-folder ul li a {
  padding: 15px 10px;
  color: #ffffff;
  font-size: 14px;
}
.business-folder ul li a img {
  height: 16px;
  margin-right: 5px;
}

/* signature tabs css start */
.tab-menu1 {
  display: flex;
  grid-gap: 24px;
  padding: 0 10px 10px;
  border-bottom: 1px solid #ddd;
  position: relative;
  flex-wrap: wrap;
  margin-top: 15px;
}
.tab-menu1 li {
  list-style: none;
}
.tab-menu1 > li {
  position: relative;
  cursor: pointer;
  color: #aaa;
  transition: all 0.3s ease;
  white-space: nowrap;
  font-size: 16px;
}
.tab-menu1 > li:hover {
  color: #444;
}
.tab-menu1 > li.active {
  color: #0e3247;
  font-weight: 600;
}

.tab-menu1 .line1 {
  position: absolute;
  bottom: 0;
  left: 10px;
  height: 2px;
  background: #e05735;
  transition: all 0.3s ease;
}
.content1 {
  padding: 0 10px 0;
  display: none;
}
.content1.active {
  display: block;
}
.text-invert {
  filter: invert(1);
}

/* Popup image */

#popupI {
  display: none;
}
.popup-containerI {
  width: 100%;
  height: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  /* background-color: rgb(96 95 127 / 70%); */
  top: 30px;
  left: 0;
}
.popupI {
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  padding: 15px;
  width: 312px;
  border-radius: 15px;
  position: relative;
}
.close-popupI {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
}
.close-popupI a {
  font-size: 0.9rem;
  background-color: #ec0000;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 35px;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  border-radius: 50%;
  display: inline-block;
}
.popupI > h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.popupI > p {
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.popup-btnI {
  display: inline-block;
  text-decoration: none;
  border: 1px solid #192252;
  background-color: #192252;
  color: #fff;
  padding: 5px 10px;
  margin: 10px 0px;
  border-radius: 6px;
  transition: 0.2s all ease-in;
}
.popup-btnI:hover {
  background-color: #303030;
  color: #fff;
}

.msImage {
  margin-left: 6rem !important;
}

.form-select:focus {
  border-color: #ff8420;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 146, 22, 0.25);
}

.DeleteDepartment {
  background-color: rgb(255, 52, 52);
}

.curserPointer {
  cursor: pointer;
}

.w-90 {
  width: 356px
}

.chevronDownRotate {
  transform: rotate(-90deg);
}

/* The snackbar - position it at the bottom and in the middle of the screen */

#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px !important; /* Set a default minimum width */
  margin-left: -125px !important; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff !important; /* White text color */
  text-align: center !important; /* Centered text */
  border-radius: 2px !important; /* Rounded borders */
  padding: 16px !important; /* Padding */
  position: fixed !important; /* Sit on top of the screen */
  z-index: 9999 !important; /* Add a z-index if needed */
  left: 50% !important; /* Center the snackbar */
  bottom: 100px !important; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
input[type="file"] {
  color: white;
  width: 110px;
}
input[type="file"]::file-selector-button {
  color: white;
  background: none;
  border: none;
  /* background-color: #e05735; */
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;
}

/* input[type="file"]::file-selector-button:hover {
  background-color: #fc7d5d;
} */
.upload-btn {
  color: white;
  background: none;
  border: none;
  background-color: #e05735;
  border-radius: 10px;
  cursor: pointer;
  padding: 0px;
  margin: 10px;
  padding-left: 4px;
}
.upload-btn:hover {
  background-color: #fc7d5d;
}
.upload-btn-white input[type="file"]::file-selector-button {
  color: black;
  color: white;
  background: orange;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;
}
.upload-btn-white input[type="file"]::file-selector-button:hover {
  background: rgb(219, 144, 5);
}

.modal.show .modal-dialog {
  transform: translate(0, 0) / translate3d(0, 0, 0);
}

.modal.fade .modal-dialog {
  transition: transform 0.2s ease-in;
}
.fade {
  transition: opacity 0.15s ease-in !important;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translateY(-50px);
  transform: translateY(0px);
}

.navLink a {
  display: block;
  color: #d0d1d2;
  font-size: 13px;
  background-color: transparent !important;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0px 12px;
  text-transform: uppercase;
}
.navLink a:hover {
  color: #11263c;
}
.active a {
  color: #11263c;
}

@media screen and (max-width: 480px) {
  .navLink a {
    margin: 0 5px;
  }
}
@media screen and (max-width: 375px) {
  .navLink a {
    display: none;
  }
}
@media screen and (min-width: 375px) {
  .view_mobile {
    display: none;
  }
}

.department-item .DeleteDepartment {
  visibility: hidden !important;
}
.active.department-item:hover .DeleteDepartment {
  visibility: visible !important;
}

/* All Card color vise change css start  */

/* vcard1 */
.vcard1-purple {
  background: linear-gradient(
    168.23deg,
    #8366b7 0.61%,
    #271138 100.1%
  ) !important;
  color: #ffffff !important;
}
.vcard1-black {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #6a6262 0%,
    #000000 100%
  ) !important;
  color: #ffffff !important;
}
.vcard1-blue {
  background: linear-gradient(167.96deg, #2e60ad 0%, #071935 100%) !important;
  color: #ffffff !important;
}
.vcard1-white {
  background: #ffffff !important;
  color: #000000 !important;
}
.vcard1-grey {
  background: linear-gradient(167.96deg, #485563 0%, #29323c 100%) !important;
}

/* vcard2 */
.vcard2-white {
  background-color: rgb(255 255 255) !important;
  color: #000000 !important;
}
.vcard2-black {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #6a6262 0%,
    #000000 100%
  ) !important;
}
.vcard2-blue {
  background: #121e60 !important;
  color: #ffffff !important;
}
.vcard2-red_magenta {
  background: #691548 !important;
  color: #ffffff !important;
}
.vcard2-yellow {
  background: #fda900 !important;
  color: #000000 !important;
}

/* vcard3 */
.vcard3-purple {
  background: linear-gradient(
    168.23deg,
    #8366b7 0.61%,
    #271138 100.1%
  ) !important;
  color: #ffffff !important;
}
.vcard3-black {
  background: #273226 !important;
  color: #ffffff !important;
}
.vcard3-blue {
  background: linear-gradient(180deg, #115077 0%, #3398d7 100%) !important;
  color: #ffffff !important;
}
.vcard3-grey {
  background: linear-gradient(168.23deg, #485563 0.61%, #29323c 100.1%);
  color: #ffffff !important;
}
.vcard3-white {
  background: #ffffff !important;
  color: #000000 !important;
}

/* vcard4 */
.vcard4-white {
  background-color: rgb(255 255 255) !important;
  color: #000000 !important;
}
.vcard4-black {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #6a6262 0%,
    #000000 100%
  ) !important;
  color: #ffffff !important;
}
.vcard4-light_coffee {
  background: #fda900 !important;
  color: #000000 !important;
}

.vcard4-light_pink {
  background: #691548 !important;
  color: #ffffff !important;
}
.vcard4-light_sky {
  background: #121e60 !important;
  color: #ffffff !important;
}

/* vcard5 */
.vcard5-black {
  background-color: rgb(255 255 255) !important;
  color: #000000 !important;
}
.vcard5-blue {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #6a6262 0%,
    #000000 100%
  ) !important;
  color: #ffffff !important;
}
.vcard5-light_green {
  background: #3aa56f !important;
  color: #000000 !important;
}
.vcard5-light_orange {
  background: #e97c74 !important;
  color: #000000 !important;
}
.vcard5-white {
  background: #fda900 !important;
  color: #000000 !important;
}

/* All Card color vise change css end  */
.card_name {
  color: #000000 !important;
}
.vcard-description p > * {
  text-align: left;
}

.from-control-search {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #dee2e6;
  font-size: 14px;
  line-height: 26px;
  background-color: #ffffff;
  border-radius: 6px;
  width: 80%;
}

.form-control-search:focus {
  border-color: #e05735;
  box-shadow: none;
}
.form-control-search:focus-visible {
  border-color: #e05735 !important;
  box-shadow: none !important;
  outline: solid 1px #e05735 !important;
}

.form-control-search[readonly] {
  background-color: #ffffff;
}

.form-control-search:disabled {
  background-color: #dee2e6;
}
.vcard3-header,
.vcard5-header,
.petcard1-header img {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.PhoneInputInput {
  border: none !important;
}
.icons2 {
  position: absolute;
  top: 18px;
  right: 18px;
  color: #162741;
}
.setting{
  width: 50px !important;
}
.organization-box{
  margin-top: -35px;
}

@media screen and (max-width:992px) {
  .organization-box{
    margin-top: 0px;
  } 
}
@media screen and (max-width:768px) {
  .logo-light-mode{
    visibility: hidden;
  }  
}

/* tooltip css start */
.tooltip2 {
  position: relative;
  display: inline-block;
}

.tooltip2 .tooltip2text {
  visibility: hidden;
  width: 120px;
  background-color: #071935;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0 3px;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}
.tooltip2 .tooltip2text::after{
  content: '';
  width: 10px;
  height: 10px;
  background-color: #071935;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: rotate(45deg) translateX(-50%);
}

.tooltip2:hover .tooltip2text {
  visibility: visible;
  transition-delay: 0.3s;
}

/* custom images modal */
/* #vcard_modal {
  display: none;
} */
.vcard_modal-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 30px;
  left: 0;
  z-index: 99999;
}
.vcard_modal {
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  padding: 15px;
  width: 312px;
  border-radius: 15px;
  position: relative;
}
.close-vcard_modal {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
}
.close-vcard_modal a {
  font-size: 0.9rem;
  background-color: #000000;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  border-radius: 50%;
  display: inline-block;
}
.vcard_modal > h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.vcard_modal > p {
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.vcard_modal-btn {
  display: inline-block;
  text-decoration: none;
  border: 1px solid #181818;
  background-color: #181818;
  color: #fff;
  padding: 7px 35px;
  margin: 10px 0px;
  border-radius: 6px;
  transition: 0.2s all ease-in;
}
.vcard_modal-btn:hover {
  background-color: #303030;
  color: #fff;
}
.vcard_modal img{
  width: 100% !important;
  height: auto !important;
  display: block;
}


.vcard_ribbon{
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.4);
  visibility: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-btn:hover .vcard_ribbon{
  visibility: visible;
}
/* .vcard_ribbon.vcard_ribbon_left {
  left: 0px;
} */
.vcard_ribbon.vcard_ribbon_warning span {
  background-color: #f17425 !important;
  color: #fff;
  /* border-top-right-radius: 20px; */
  /* border-bottom-right-radius: 20px; */
  border-radius: 20px;
  padding: 8px 14px
}

/* custom link css */
/* .customLinkFont{
  font-family: 'Courier New', Courier, monospace;
} */

/* CSS FOR PROGRESS BAR*/

/* For Firefox */
progress::-moz-progress-bar {
  background: #E05735;
}

/* For Chrome or Safari */
progress::-webkit-progress-value {
  background: #E05735;
}

/* For IE10 */
progress {
  background: #E05735;
}

/* Common css for Vcard and Website Template */

.imgStretch {
  object-fit: contain;
}

.aboutTextWrap{
 overflow-wrap: break-word;
}

.profileWordWrap{
  overflow-wrap: anywhere;
}
.custom_note {
  white-space: break-spaces;
}

@media (max-width: 400px){
  .saveWallet{
    font-size: 10px !important;
  }
}